.start-container {
  width: 100%;
  position: absolute;
  top: 65%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.start-container > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(95, 35, 72);
  background: rgba(255, 255, 255, 0.3);
  width: 75px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(95, 35, 72);
  letter-spacing: 2px;
  transition: all ease 0.3s;
}

.start-container > .start:hover {
  background: rgb(95, 35, 72);
  color: #ffffff;
}

.start-container > .start.en {
  margin-right: 20px;
}

.start-container > .start.de {
  margin-left: 20px;
}
