.background > .brightness-layer {
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
  z-index: 1;
}

.logo {
  width: 295px;
  height: auto;
  z-index: 1;
}

.landscape,
.portrait {
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landscape > .background,
.portrait > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portrait > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.landscape > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.landscape > .explore-btn,
.portrait > .explore-btn {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 5px;

  padding: 10px 16px;
  margin-top: calc(var(--vh, 1vh) * 8.9);
}

.landscape > .moreInfo,
.portrait > .moreInfo {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
  margin-top: calc(var(--vh, 1vh) * 6.4);
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9.24848px;
  line-height: 13px;
  /* identical to box height */

  text-transform: lowercase;

  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy > svg,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy > svg {
  width: 109px;
  height: auto;
  margin-top: 6px;
}

.landscape > .moreInfoContent,
.portrait > .moreInfoContent {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.landscape > .moreInfoContent > .contentWrapper,
.portrait > .moreInfoContent > .contentWrapper {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(var(--main-color));
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 5px;
}

.portrait > .moreInfoContent > .contentWrapper {
  height: 50vh;
}

.landscape > .moreInfoContent > .contentWrapper > svg,
.portrait > .moreInfoContent > .contentWrapper > svg {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > svg > *,
.portrait > .moreInfoContent > .contentWrapper > svg > * {
  fill: #ffffff;
}

.landscape > .moreInfoContent > .contentWrapper > h1,
.portrait > .moreInfoContent > .contentWrapper > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;

  /* branco */

  color: #ffffff;
  margin: 0 0 24px 0;
}

.landscape > .moreInfoContent > .contentWrapper > p,
.portrait > .moreInfoContent > .contentWrapper > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0 0 16px 0;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg,
.portrait > .moreInfoContent > .contentWrapper > p > svg {
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg > *,
.portrait > .moreInfoContent > .contentWrapper > p > svg > * {
  fill: #ffffff;
}

.portrait-links {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
}

.portrait-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.portrait-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.portrait-links > .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portrait-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 255px;
  width: calc(var(--vw, 1vw) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
}

.portrait-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.portrait-links > .wrapper > .img-link > p,
.portrait-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landscape-links {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  background-color: #25282e;
}

.landscape-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.landscape-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.landscape-links > .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #161616;
}

.landscape-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 420px;
  height: calc(var(--vh, 1vh) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  margin-right: 10px;
}

.landscape-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.landscape-links > .wrapper > .img-link > p,
.landscape-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landing-page-mobile {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile > .background-mobile {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}

.landing-page-mobile > .block-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile > .val-logo-mobile {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile {
  width: 100%;
  position: fixed;
  top: 75%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.landing-page-mobile > .mb-logo-mobile {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

/*landscape*/
.landing-page-mobile-l {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile-l > .background-mobile-l {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.landing-page-mobile-l > .block-mobile-l {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile-l > .val-logo-mobile-l {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l {
  width: 100%;
  position: fixed;
  top: 75%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.en {
  margin-right: 10px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.de {
  margin-left: 10px;
}

.landing-page-mobile-l > .mb-logo-mobile-l {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

.landing-page > .background-image,
.landing-page > .vert-background-image {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing-page > .background-image > img {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, 0);
}

.landing-page > .vert-background-image > img {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, 0);
}

.landing-page > .vert-center-container > .cov-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .cov-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .vert-center-container > .cov-container > .cov {
  width: 35vw;
  height: auto;
}

.landing-page > .center-container > .cov-container > .cov {
  width: 9.5vw;
  height: auto;
}

.landing-page > .vert-center-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70vh;
  padding: 10vh 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100vw;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .vert-center-container > .info {
  flex: 1;
  font-family: ProximaNova;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .info {
  flex: 4;
  font-family: ProximaNova;
}

.landing-page > .vert-center-container > .info > h1 {
  margin: 0;
  font-size: 5.5vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > h1 {
  margin-top: 0;
  font-size: 2.3vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .vert-center-container > .info > p {
  width: 75%;
  font-size: 4vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > p {
  width: 75%;
  font-size: 1.2vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.landing-page > .vert-center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-top: 3vh;
}

.landing-page > .vert-center-container > .info > .buttons-container > .start {
  width: 50%;
  font-size: 4vw;
  line-height: 210%;

  color: #fff;

  background: #6dc26d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start {
  width: 35%;
  font-size: 1vw;
  line-height: 210%;

  color: #fff;

  background: #6dc26d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #5cb85c;
}

.landing-page > .logo {
  position: fixed;
  left: 50%;
  bottom: 1%;
  transform: translateX(-50%);
  width: 10%;
  height: auto;
}

.landing-page > .vert-logo {
  position: fixed;
  left: 50%;
  bottom: 1%;
  transform: translateX(-50%);
  width: 25%;
  height: auto;
}

.landing-page > .sponsor {
  position: fixed;
  bottom: 1.5%;
  right: 6%;
  width: 2.3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .vert-sponsor {
  position: fixed;
  bottom: 1.5%;
  right: 9%;
  width: 5.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .sponsor > p {
  flex: 1;
  font-family: ProximaNova;
  font-size: 1vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  margin: 0 0 0.5vw 0;
}

.landing-page > .vert-sponsor > p {
  flex: 1;
  font-family: ProximaNova;
  font-size: 2vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  margin: 0 0 1vw 0;
}

.landing-page > .sponsor > img,
.landing-page > .vert-sponsor > img {
  flex: 1;
  width: 100%;
}

@font-face {
  font-family: ProximaNova;
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot');
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.svg#Proxima Nova') format('svg');
}

@font-face {
  font-family: ProximaNovaT;
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot');
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.svg#Proxima Nova Thin')
      format('svg');
}
