.main-bar {
  position: fixed;
  right: 2.4vw;
  top: 90.93vh;
  width: auto;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 100;
  padding: 0 0 0 0.83vw;
  opacity: 0.65;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-bar.thumbnails {
  top: 79.63vh;
}

.main-bar:hover {
  opacity: 1;
}

.main-bar > p {
  flex: 5;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 1vw;

  color: #101010;
}

.main-bar > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-bar svg {
  flex: 1;

  width: 24px;
  height: 24px;

  margin: 12px 16px 12px 0;

  cursor: pointer;
}

.main-bar svg > * {
  fill: #101010;
}

.progress-bar {
  position: fixed;
  right: 2.4vw;
  top: 89vh;
  width: 134px;
  height: 8px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 100;
}

.progress-bar > .progress {
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  transition: width 1s ease;
}

.thumbnails-menu {
  position: fixed;
  height: 13.7vh;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  overflow-x: auto;
}

.thumbnails-menu > .selector {
  flex: 0 0 8.44vw;
  height: 10vh;
  border-radius: 5px;
  margin-right: 16px;
  cursor: pointer;
}

.thumbnails-menu > .gradient-thumbnail > .selector {
  width: 100%;
  height: 100%;
  margin-right: 16px;
}

.thumbnails-menu > .gradient-thumbnail > .selector.selected {
  border: 3px solid rgb(var(--main-color));
  box-sizing: border-box;
}

.thumbnails-menu > .gradient-thumbnail {
  flex: 0 0 8.44vw;
  height: 10vh;
  margin-right: 16px;
  cursor: pointer;
}

.thumbnails-menu > .gradient-thumbnail > .gradient {
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  padding: 0.57vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.mobile-navBar {
  width: 100vw;
  height: 45px;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}

.mobile-navBar > p {
  flex: 10;
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0;
}

.mobile-navBar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  pointer-events: all;
}

.mobile-navBar > svg > * {
  fill: #949494;
}

.mobile-navBar > svg.share-icon > g {
  filter: none;
}

.mobile-navBar > svg.share-icon > g > path {
  fill: #949494;
}

.mobile-navBar > svg.mapActive > *,
.mobile-navBar > svg.thumbnails > *,
.mobile-navBar > svg.infoActive > * {
  fill: rgb(var(--main-color));
}

.mobile-thumbnails-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px);
  background: #ffffff;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  z-index: 399;
}

.mobile-thumbnails-menu > .selector {
  width: 20%;
  height: 20vh;
  margin-left: 1.2vw;
  cursor: pointer;
  border-radius: 10px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .selector {
  width: 100%;
  height: 100%;
}

.mobile-thumbnails-menu > .gradient-thumbnail {
  height: 100px;
  width: 30%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .gradient {
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #ffffff;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 16px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .selector.selected {
  border: 3px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 10px;
}

.mobile-slideshow-menu {
  width: 100%;
  height: 45px;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.mobile-slideshow-menu > p {
  flex: 10;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* black */

  color: #101010;
  margin: 0;
}

.mobile-slideshow-menu > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-slideshow-menu > svg > * {
  fill: #949494;
}

.mobile-slideshow-menu > svg.close {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-slideshow-menu > svg.close > * {
  fill: #101010;
}

.mobile-slideshow-menu > svg.pause > * {
  fill: rgb(var(--main-color));
}

.mobile-slideshow-menu > .mobile-progress-bar {
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 0;
  position: absolute;
  top: -4px;
}

.mobile-slideshow-menu > .mobile-progress-bar > .progress {
  background: rgb(var(--main-color));
  height: 100%;
  transition: width 1s ease;
}

.toolbar {
  position: fixed;
  z-index: 296;
  top: 91.94vh;
  left: 3.23vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toolbar > * {
  margin-right: 24px;
}

.mobile-shared {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.3vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  transition: all 0.5s ease;
  z-index: 200;
}

.mobile-timelapse-menu {
  width: 100vw;
  height: 6vw;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-timelapse-menu > svg {
  width: 4.5vw;
  height: auto;
  margin-right: 2vw;
}

.mobile-timelapse-menu > svg > * {
  fill: #949494;
}

.mobile-timelapse-menu > svg.close {
  width: 4vw;
  height: auto;
  margin-right: 2vw;
}

.mobile-timelapse-menu > svg.close > * {
  fill: #101010;
}

.mobile-timelapse-menu > svg.pause > * {
  fill: rgb(var(--main-color));
}

.mobile-timelapse-menu > .line {
  width: 72%;
  border: 0;
  background-color: #101010;
  height: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  margin-right: 2vw;
}

.mobile-timelapse-menu > .line > .time-selector {
  width: 1.6vw;
  height: 1.6vw;
  background: #ffffff;
  border: 1px solid #101010;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-timelapse-menu > .line > .time-selector.selected {
  width: 3vw;
  height: 3vw;
  border: 2px solid #101010;
}

.mobile-timelapse-menu > .line > .time-selector > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -310%);
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 100%;
  /* identical to box height, or 100% */

  /* black */

  color: #101010;

  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.mobile-timelapse-menu > .line > .time-selector.selected > p {
  opacity: 1;
}

.pa {
  position: relative;
}

.pa > svg {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 5vh;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  cursor: pointer !important;
}

.pa > svg.back-pa {
  opacity: 0;
  margin-left: -0.5px;
  cursor: pointer !important;
}

.pa > svg.back-pa * {
  cursor: pointer !important;
}

.pa > svg.back-pa:hover {
  opacity: 1;
}

.pa > svg.svg-pa {
  width: auto;
  height: 5.6vh;
  z-index: 0;
  filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}

.pa > div > svg.close-pa {
  position: fixed;
  right: 5vw;
  top: 7vh;
  background: #101010;
  opacity: 0.6;
  border-radius: 50%;
  cursor: pointer;
}

.pa > div > svg.close-pa * {
  cursor: pointer;
}

.pa > div > .info-pa {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: pre-line;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.6vw;
  line-height: 117%;
  /* or 117% */

  text-align: center;

  /* White */

  color: #ffffff;
  /* shadow-text */

  text-shadow: 0px 1px 5px #000000;
}

.mobile-pa-bar {
  width: 100vw;
  height: 6vw;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 401;
  padding: 0 0 0 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-pa-bar > .mobile-close-pa {
  width: 4vw;
  height: auto;
  margin-right: 4vw;
  z-index: 1;
}

.mobile-pa-bar > .mobile-close-pa > * {
  fill: #101010;
}

.mobile-pa-bar > .mobile-info-pa {
  position: absolute;
  width: 100%;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #101010;
  margin: 0;
  text-align: center;
}
