.chat-container {
  width: 330px;
  //height: 528px;
  background-color: #ffffff;
  //background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.typo-chat-title {
  font-family: Mukta Vaani;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  color: #ffffff;
}

.typo-body-chat {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.typo-chat-input {
  color: #808080;
}

/*
// chat-text-field

position: absolute;
width: 218px;
height: 48px;
left: 16px;
top: 464px;

background: #EEF1F6;
border-radius: 65px;


//Aa 

position: absolute;
width: 21px;
height: 11px;
left: 32px;
top: 482px;

font-family: Nunito Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
display: flex;
align-items: center;

color: #808080;
*/

/*

position: absolute;
width: 213px;
height: 43px;
left: 24px;
top: 270px;

background: #E9EBED;
border-radius: 10px;

*/
