.messages-container {
  overflow: auto;
  margin-left: 4.8%;
  margin-right: 4.8%;
  margin-bottom: 30px;
  margin-top: 30px;
  height: 373px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .chat-message {
    max-width: 180px;
    margin-bottom: 4px;
    padding: 16px;
  }

  .client-message {
    background: rgb(var(--main-dark));
    color: white;
    border-radius: 10px 10px 0px 10px;
    margin-left: calc(100% - 180px);
    white-space: pre-wrap;
    word-break: break-word;
    user-select: text;
  }

  .support-message {
    background: #e9ebed;
    border-radius: 10px 10px 10px 0px;
  }
}
