.title-indication {
  position: fixed;
  top: 25vh;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: Mukta Vaani;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 51px;
  /* identical to box height, or 255% */

  text-align: center;
  letter-spacing: 0.0025em;

  color: #ffffff;

  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
}

.input-text-container {
  height: 48px;
  margin-bottom: 16px;
  background-color: #ffffff;

  form {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    .typo-body-chat {
      background: #eef1f6;
      border-radius: 65px;
      resize: none;
      width: 100%;
      height: 100%;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #808080;
      padding: 12px 16px;
      border: none;
    }

    .typo-body-chat.changed {
      color: #050505;
    }

    .typo-body-chat:focus {
      outline: none;
    }

    .typo-body-chat::-webkit-scrollbar {
      width: 0; /* width of the entire scrollbar */
      height: 0;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      cursor: pointer;

      * {
        fill: #c4c4c4;
      }
    }

    svg.pa-active {
      * {
        fill: rgb(var(--main-dark));
      }
    }

    button {
      background: transparent;
      border: none;
      padding: 0;
      width: 24px;
      height: 24px;
      margin-left: 16px;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
        margin-left: 0;

        * {
          fill: rgb(var(--main-dark));
        }
      }
    }

    button:focus {
      outline: none;
    }
  }
}
