.chatbox-container {
  width: 24px;
  height: 24px;
}

.chatbox-container > svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 1.66667px 3.33333px #000000);
  cursor: pointer;
}

.chatbox-container > svg > * {
  fill: #ffffff;
}

.chatbox-container > .chatbox-window {
  position: fixed;
  bottom: 10vh;
  left: 3.23vw;
}
