.hotspot-info > .content {
  position: absolute;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0;
  margin: 0;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);

  -webkit-transition: opacity 0.3s, -webkit-transform 0.5s;
  transition: opacity 0.3s, transform 0.5s;

  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-info > .content.active {
  opacity: 1;
  pointer-events: all;
}

.hotspot-info > .content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  margin: 0;
  padding: 16px 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hotspot-info > .content > h3 > .close {
  background: rgb(var(--main-dark));
  margin: 0;
  padding: 0.5vw 0.7vw 0.3vw 0.7vw;
  cursor: pointer;
  pointer-events: all;
}

.hotspot-info > .content > h3 > .close > img {
  width: 2vw;
}

.hotspot-info > .content > p {
  background: #ffffff;
  width: 100%;
  height: auto;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;
  color: #000004;
  margin: 0;
  padding: 16px 16px;
}

.hotspot-info > .content > .button-link {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  margin: 0 18px 16px 18px;
  width: 10vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.83vw;
  line-height: 150%;
  color: #141414;
  text-align: center;
  cursor: pointer;
}

.hotspot-info > .content > .contact-info {
  color: #999ca1;
  margin: 0 18px 16px 18px;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 10px;
  line-height: 100%;
  cursor: pointer;
  padding: 0;
  white-space: pre-wrap;
  letter-spacing: 0.1px;
}

.hotspot-info > svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
}

.hotspot-info > svg:hover {
  width: 60px;
}

.hotspot-info > svg * {
  filter: none;
}

.hotspot-info .hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0.9;
}

.hotspot-info .hotspot-hover {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-info .hotspot-hover.active {
  opacity: 1;
}

.hotspot-info:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-info .hotspot-oval {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-info .hotspot-oval.active {
  opacity: 1;
}

.info-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 10vh;
}

.info-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.info-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.info-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.info-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));

  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;

  margin: 0;

  padding-left: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.info-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-hotspot-container > .mobile-content > h3 > .close > svg {
  width: 24px;
  height: 24px;
}

.info-hotspot-container > .mobile-content > h3 > .close > svg > * {
  fill: #ffffff;
}

.info-hotspot-container > .mobile-content > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  height: 100%;
  margin: 0;
  padding: 16px 16px 60px 16px;

  overflow-y: auto;

  background: #ffffff;
}

.info-hotspot-container > .mobile-content > p::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.info-hotspot-container > .mobile-content > .button-link {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  margin: 0 4vw 2vw 4vw;
  width: 30vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 2.4;
  color: #141414;
  text-align: center;
}

.info-hotspot-container > .mobile-content > .contact-info {
  color: #999ca1;
  margin: 0 4vw 2vw 4vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 100%;
  cursor: pointer;
  padding: 0;
  white-space: pre-wrap;
  letter-spacing: 0.1px;
}
