.navBar-modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  z-index: 99;
  transition: width 0.5s ease;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  max-height: 95vh;
  width: 45vw;
}

.navBar-modal-expo > .modal-wrapper > img {
  max-height: 55vh;
  margin-right: 2.5vw;
}

.navBar-modal-expo > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-expo > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navBar-modal-expo > .modal-wrapper,
.modal-expo > .modal-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 2vw 2.5vw 0 2.5vw;
}

.modal-expo > .modal-wrapper > .main-img,
.navBar-modal-expo > .modal-wrapper > .main-img {
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden;
  margin-right: 2.5vw;
}

.modal-expo > .modal-wrapper > .information,
.navBar-modal-expo > .modal-wrapper > .information {
  position: relative;
  min-width: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.modal-expo > h1,
.navBar-modal-expo > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 98%;
  letter-spacing: 0.1vw;

  width: 100%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translate(0, -10%);
}

.navBar-modal-expo > .modal-wrapper > .information > h1,
.modal-expo > .modal-wrapper > .information > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 0;
  margin-bottom: 0.5vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > p,
.modal-expo > .modal-wrapper > .information > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 1.2vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 0;
  margin-bottom: 0.5vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > h2,
.modal-expo > .modal-wrapper > .information > h2 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 1vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > h2 > span,
.modal-expo > .modal-wrapper > .information > h2 > span {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: rgb(var(--main-color));

  overflow-y: scroll;

  margin-top: 1vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > div,
.modal-expo > .modal-wrapper > .information > div {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 3vw;
  color: rgb(var(--main-color));

  border: 2px solid rgb(var(--main-color));
  width: 80%;

  text-align: center;
  vertical-align: middle;

  margin-top: 1vw;
  cursor: pointer;
  letter-spacing: 0.1vw;
}

.modal-expo > .gallery,
.navBar-modal-expo > .gallery {
  width: 100%;
  height: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1vw 2.5vw 0 2.5vw;
  overflow-x: scroll;
}

.modal-expo > .gallery > img,
.navBar-modal-expo > .gallery > img {
  width: 4vw;
  height: auto;
  margin-right: 1vw;
  opacity: 0.5;
  cursor: pointer;
  border: 3px solid transparent;
}

.modal-expo > .gallery > img.selected,
.navBar-modal-expo > .gallery > img.selected {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
  background-color: rgb(var(--main-color));
}

.modal-expo > .footer,
.navBar-modal-expo > .footer {
  width: 100%;
  font-family: 'Merriweather Sans', sans-serif;
  font-style: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 1.1vw;
  /* identical to box height */

  color: #999ca2;

  padding-left: 2.5vw;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;

  white-space: pre-wrap;
}

.modal-expo > .footer > p,
.navBar-modal-expo > .footer > p {
  display: inline-block;
  cursor: pointer;
}

.information > .logoCov {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40%;
  height: auto;
  transform: translateX(-50%);
}

.navBar-modal-expo > svg,
.modal-expo > svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.modal-expo > h1 > svg,
.navBar-modal-expo > h1 > svg {
  width: 2.8vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
  cursor: pointer;
}

.mobile-modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-expo > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 4vw;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-modal-expo > h1 > svg {
  width: 4.9vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
}

.mobile-modal-expo > .mobile-content-expo {
  height: 88.5%;
  width: 100%;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-modal-expo > .mobile-content-expo > * {
  flex-shrink: 0;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text {
  flex: 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.4vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h2 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 2vw;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h2 > span {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.2vw;
  line-height: 144%;
  color: rgb(var(--main-color));
  margin-top: 2vw;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > div {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 2.4;
  color: rgb(var(--main-color));

  border: 2px solid rgb(var(--main-color));
  width: 50%;

  text-align: center;
  vertical-align: middle;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 1vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-modal-img {
  flex: 1;
  width: 100%;
  height: 80%;
  margin-bottom: 0;
  overflow: hidden;
  margin: 0 4vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-modal-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > .logoCov {
  width: 40%;
  height: auto;
  margin-bottom: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > svg {
  width: 6vw;
  height: auto;
}

.mobile-modal-expo > .mobile-content-expo > svg > * {
  fill: #949494;
}
