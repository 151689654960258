.nadirHS {
  visibility: visible;
  width: 800px;
  height: 800px;
  background: #000;
  border-radius: 50%;
}

#nadirHS {
  border: 4px green solid;
  border-radius: 50%;
}
