@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-color: #000;
  color: #fff;
}

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button.ytp-large-play-button {
  display: none !important;
}


body {
  background: #ffffff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--main-color)) transparent;
  scroll-behavior: smooth;
  line-height: 1.6;
}

::-webkit-scrollbar {
  width: 0.5vw; /* width of the entire scrollbar */
  height: 0.5vw;
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: rgb(var(--main-color)); /* color of the scroll thumb */
  border-radius: 70px; /* roundness of the scroll thumb */
}

div {
  position: relative;
}

.landing-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-page.linea {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40% 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page.linea > .fachada {
  width: 44%;
  height: 86%;
}

.landing-page.linea > .fachada > * {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
}

.landing-page.linea > .fachada > svg > g {
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  pointer-events: all;
}

.landing-page.linea > .fachada > svg > g:hover {
  opacity: 1;
}

.landing-page.linea > .logo {
  position: absolute;
  top: 7%;
  left: 10.5vw;
  width: 5vw;
  height: auto;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #5cb85c;
}

.landing-page > .logo {
  position: absolute;
  left: 50%;
  bottom: 3%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 15%;
  height: auto;
}

.landing-page > .sponsor > img {
  flex: 1 1;
  width: 100%;
}

#rotatePage {
  width: 100vw;
  height: 100vh;
  background: rgba(var(--main-color), 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8vh 0 14vh 0;
}

#rotatePage > .rotateDevice {
  flex: 6 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#rotatePage > .rotateDevice > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 5vw;
  line-height: 1.6;
  text-align: center;
  width: 75%;
}

#rotatePage > .rotateDevice > img {
  width: 30vw;
}

#rotatePage > h1 {
  flex: 1 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 6vw;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

#rotatePage > .footer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#rotatePage > .footer > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 120%;
  text-align: center;
  margin: 0 0 3vw 0;
}

#rotatePage > .footer > img {
  width: 40vw;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url(https://covilha.alive.vision/static/media/MYRIADPRO-REGULAR.d75d7f70.OTF) format('truetype');
}

body {
  background: #292929;
}

.landing-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page > img.background {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.landing-page > img.val-logo {
  position: fixed;
  top: 40px;
  left: 40px;
  width: 195px;
  height: auto;
  z-index: 1;
}

.landing-page > .mb-logo {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: auto;
  z-index: 1;
}

.landing-page > .pb {
  position: fixed;
  bottom: 80px;
  left: 75vw;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
}

.landing-page > .block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page > .title {
  position: fixed;
  top: 35%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  line-height: 115px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1;
}

.landing-page > .start-container {
  width: 100%;
  position: fixed;
  top: 75%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page > .start-container > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 10vw;
  height: 8vh;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
}

.landing-page > .start-container > .start:hover {
  background: rgb(95, 35, 72);
  color: #ffffff;
}

.landing-page > .start-container > .start.en {
  margin-right: 20px;
}

.landing-page > .start-container > .start.de {
  margin-left: 20px;
}

.landing-page > .center-container > .cov-container {
  flex: 3 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .cov-container > .cov {
  width: 15vw;
  height: auto;
}

.landing-page > .center-container {
  position: fixed;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100vw;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .info {
  flex: 4 1;
  font-family: Mukta Vaani;
}

.landing-page > .center-container > .info > h1 {
  margin-top: 0;
  font-size: 2.3vw;
  text-shadow: 4px 4px 7px black;
}

.landing-page > .center-container > .info > p {
  width: 75%;
  font-size: 1.5vw;
  text-shadow: 3px 3px 6px black;
}

.landing-page > .center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.landing-page > .center-container > .info > .buttons-container > .start {
  width: 35%;
  font-size: 1.1vw;
  line-height: 210%;
  color: #fff;
  background: #6dc26d;
  box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
  border-radius: 0;
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #6ec76e;
}

.landing-page > .logo {
  position: absolute;
  left: 50%;
  bottom: 3%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 15%;
  height: auto;
}

.landing-page > .sponsor {
  position: absolute;
  bottom: 3%;
  right: 4%;
  width: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .sponsor > p {
  flex: 1 1;
  font-family: Mukta Vaani;
  font-size: 1vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
}

.landing-page > .sponsor > img {
  flex: 1 1;
  width: 100%;
}

.stop-sharing-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #92de47;
  pointer-events: none;
}

.remote-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: 5px solid #d92626;
  pointer-events: all;
}

.stop-sharing-indicator > .ss-container {
  position: fixed;
  left: 50%;
  bottom: 10px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stop-sharing-indicator > .ss-container > .ss-white {
  background: #ffffff;
  opacity: 0.85;
  border-radius: 5px 0 0 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #000004;
  padding: 8px 16px;
}

.stop-sharing-indicator > .ss-container > .ss-red {
  background: #d92626;
  opacity: 0.85;
  border-radius: 0 5px 5px 0;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  padding: 2px 16px 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
}

.limit-reached {
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.block-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
  z-index: 101;
}

.block-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 214px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.block-container > svg {
  width: 70px;
  height: 70px;
}

.block-container > svg > * {
  fill: #000000;
}

.block-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.block-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.block-container > p > svg > * {
  fill: #000000;
}

.block-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.block-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.block-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}

.lobby-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(50, 50, 50, 0.9);
  pointer-events: all;
}

.background > .brightness-layer {
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
  z-index: 1;
}

.logo {
  width: 295px;
  height: auto;
  z-index: 1;
}

.landscape,
.portrait {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landscape > .background,
.portrait > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portrait > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.landscape > .background > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.landscape > .explore-btn,
.portrait > .explore-btn {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 5px;

  padding: 10px 16px;
  margin-top: calc(1vh * 8.9);
  margin-top: calc(var(--vh, 1vh) * 8.9);
}

.landscape > .moreInfo,
.portrait > .moreInfo {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
  margin-top: calc(1vh * 6.4);
  margin-top: calc(var(--vh, 1vh) * 6.4);
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9.24848px;
  line-height: 13px;
  /* identical to box height */

  text-transform: lowercase;

  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landscape > .moreInfoContent > .contentWrapper > .poweredBy > svg,
.portrait > .moreInfoContent > .contentWrapper > .poweredBy > svg {
  width: 109px;
  height: auto;
  margin-top: 6px;
}

.landscape > .moreInfoContent,
.portrait > .moreInfoContent {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.landscape > .moreInfoContent > .contentWrapper,
.portrait > .moreInfoContent > .contentWrapper {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgb(var(--main-color));
  width: 90vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 5px;
}

.portrait > .moreInfoContent > .contentWrapper {
  height: 50vh;
}

.landscape > .moreInfoContent > .contentWrapper > svg,
.portrait > .moreInfoContent > .contentWrapper > svg {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > svg > *,
.portrait > .moreInfoContent > .contentWrapper > svg > * {
  fill: #ffffff;
}

.landscape > .moreInfoContent > .contentWrapper > h1,
.portrait > .moreInfoContent > .contentWrapper > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;

  /* branco */

  color: #ffffff;
  margin: 0 0 24px 0;
}

.landscape > .moreInfoContent > .contentWrapper > p,
.portrait > .moreInfoContent > .contentWrapper > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0 0 16px 0;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg,
.portrait > .moreInfoContent > .contentWrapper > p > svg {
  width: 24px;
  height: 24px;
}

.landscape > .moreInfoContent > .contentWrapper > p > svg > *,
.portrait > .moreInfoContent > .contentWrapper > p > svg > * {
  fill: #ffffff;
}

.portrait-links {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
}

.portrait-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.portrait-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.portrait-links > .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portrait-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 255px;
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
}

.portrait-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.portrait-links > .wrapper > .img-link > p,
.portrait-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landscape-links {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  background-color: #25282e;
}

.landscape-links::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.landscape-links > svg {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.landscape-links > .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #161616;
}

.landscape-links > .wrapper > .img-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 420px;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  margin-right: 10px;
}

.landscape-links > .wrapper > .img-link > h1 {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
  /* or 125% */

  /* white */

  color: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.85);
  margin: 8px 0 0 0;
}

.landscape-links > .wrapper > .img-link > p,
.landscape-links > .wrapper > .img-link > div > p {
  z-index: 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* branco */

  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.85);
  width: 290px;
  margin: 0;
}

.landing-page-mobile {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile > .background-mobile {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}

.landing-page-mobile > .block-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile > .val-logo-mobile {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile {
  width: 100%;
  position: fixed;
  top: 75%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile > .start-container-mobile > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.landing-page-mobile > .mb-logo-mobile {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

/*landscape*/
.landing-page-mobile-l {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.landing-page-mobile-l > .background-mobile-l {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.landing-page-mobile-l > .block-mobile-l {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 1;
  background: #252525;
  opacity: 0.2;
}

.landing-page-mobile-l > .val-logo-mobile-l {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l {
  width: 100%;
  position: fixed;
  top: 75%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.landing-page-mobile-l > .start-container-mobile-l > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  background: rgba(255, 255, 255, 0.3);
  width: 200px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(var(--main-color));
  letter-spacing: 2px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.en {
  margin-right: 10px;
}

.landing-page-mobile-l > .start-container-mobile-l > .start.de {
  margin-left: 10px;
}

.landing-page-mobile-l > .mb-logo-mobile-l {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: auto;
  z-index: 1;
}

.landing-page > .background-image,
.landing-page > .vert-background-image {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing-page > .background-image > img {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, 0);
}

.landing-page > .vert-background-image > img {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, 0);
}

.landing-page > .vert-center-container > .cov-container {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .cov-container {
  flex: 3 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .vert-center-container > .cov-container > .cov {
  width: 35vw;
  height: auto;
}

.landing-page > .center-container > .cov-container > .cov {
  width: 9.5vw;
  height: auto;
}

.landing-page > .vert-center-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70vh;
  padding: 10vh 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container {
  position: fixed;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100vw;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.landing-page > .vert-center-container > .info {
  flex: 1 1;
  font-family: ProximaNova;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page > .center-container > .info {
  flex: 4 1;
  font-family: ProximaNova;
}

.landing-page > .vert-center-container > .info > h1 {
  margin: 0;
  font-size: 5.5vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > h1 {
  margin-top: 0;
  font-size: 2.3vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .vert-center-container > .info > p {
  width: 75%;
  font-size: 4vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > p {
  width: 75%;
  font-size: 1.2vw;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.landing-page > .center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.landing-page > .vert-center-container > .info > .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-top: 3vh;
}

.landing-page > .vert-center-container > .info > .buttons-container > .start {
  width: 50%;
  font-size: 4vw;
  line-height: 210%;

  color: #fff;

  background: #6dc26d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start {
  width: 35%;
  font-size: 1vw;
  line-height: 210%;

  color: #fff;

  background: #6dc26d;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: none;
}

.landing-page > .center-container > .info > .buttons-container > .start:hover {
  background-color: #5cb85c;
}

.landing-page > .logo {
  position: fixed;
  left: 50%;
  bottom: 1%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 10%;
  height: auto;
}

.landing-page > .vert-logo {
  position: fixed;
  left: 50%;
  bottom: 1%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 25%;
  height: auto;
}

.landing-page > .sponsor {
  position: fixed;
  bottom: 1.5%;
  right: 6%;
  width: 2.3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .vert-sponsor {
  position: fixed;
  bottom: 1.5%;
  right: 9%;
  width: 5.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.landing-page > .sponsor > p {
  flex: 1 1;
  font-family: ProximaNova;
  font-size: 1vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  margin: 0 0 0.5vw 0;
}

.landing-page > .vert-sponsor > p {
  flex: 1 1;
  font-family: ProximaNova;
  font-size: 2vw;
  line-height: 89%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  margin: 0 0 1vw 0;
}

.landing-page > .sponsor > img,
.landing-page > .vert-sponsor > img {
  flex: 1 1;
  width: 100%;
}

@font-face {
  font-family: ProximaNova;
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot');
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.svg#Proxima Nova') format('svg');
}

@font-face {
  font-family: ProximaNovaT;
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot');
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.svg#Proxima Nova Thin')
      format('svg');
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(35, 35, 35, 0.5);
  z-index: 10;
  pointer-events: none;
}

.overlay.inactive {
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease;
}

.overlay .info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.overlay .info .infoBox {
  width: 33%;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0 16px;
}

.overlay .info .infoBox p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3vh;
  line-height: 120%;

  text-align: center;

  color: #000000;
  margin: 3vh 0 4vh 0;
}

.overlay .info .infoBox .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.overlay .info .infoBox .row .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.overlay .info .infoBox .row .column .img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 17px 0 15px 0;
}

.overlay > .info > .infoBox > .row > .column > .img-wrapper > svg > * {
  stroke: rgb(var(--main-color));
}

.overlay .info .infoBox .row .column .img-wrapper.border {
  border-left: 1px solid rgb(var(--main-color));
}

.overlay .info .infoBox .row .column p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vh;
  line-height: 110%;

  text-align: center;

  color: #000000;
  margin: 2vh 0 3vh 0;
}

.overlay .footer {
  visibility: visible;
  position: fixed;
  bottom: 6%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-end;
}

.overlay .footer .left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.overlay .footer .center {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay .footer .center p {
  align-self: center;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.8vw;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0.7vw 0;

  color: #ffffff;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay .footer .center img {
  width: 14vw;
  height: auto;
  align-self: center;
}

.overlay .footer .center > svg {
  width: 14vw;
  height: auto;
  align-self: center;
}

.overlay .footer .right {
  visibility: visible;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.overlay .footer .right .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.overlay .footer .right p {
  align-self: flex-start;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.8vw;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0.5vw 0;

  color: #ffffff;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay .footer .right img {
  width: 8vw;
  height: auto;
  margin-right: 2vw;
}

.overlay > .footer > .right > .row > svg {
  width: 8vw;
  height: auto;
  margin-right: 2vw;
}

.dropdown-container {
  position: fixed;
  top: 7vh;
  right: 5vw;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.dropdown-container.vert {
  position: fixed;
  top: 7vh;
  right: 1vw;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.dropdown-container > .dropdown-language {
  width: 6.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown-container.vert > .dropdown-language {
  width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown-container > .dropdown-language > svg {
  height: auto;
  -webkit-filter: drop-shadow(0px 1.66667px 3.33333px #000000);
          filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}

.dropdown-container > .language-list {
  display: none;
  background: #ffffff;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 1.2vw;
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.dropdown-container:hover > .language-list {
  opacity: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: height 2s ease;
  pointer-events: all;
}

.dropdown-container > .language-list > .language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-container > .language-list > .language > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.2vw;
  line-height: 110%;

  color: #000000;
  margin: 0.3vw;
}

.dropdown-container.vert > .language-list > .language > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4vw;
  line-height: 110%;

  color: #000000;
  margin: 2vw;
}

.dropdown-container > .language-list > .language > img {
  width: 1.8vw;
  margin-right: 0.6vw;
}

.dropdown-container.vert > .language-list > .language > img {
  width: 6vw;
  margin-right: 0.6vw;
}

.logo-container {
  position: fixed;
  top: 4.63vh;
  left: 3.13vw;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo-container > .main-logo {
  width: 150px;
  height: auto;
}

.logo-container > .back-container {
  width: 24px;
  height: 24px;
  margin-right: 0.83vw;
}

.logo-container > .back-container > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.logo-container > .back-container > svg:hover {
  opacity: 1;
}

.hotspot-info > .content {
  position: absolute;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0;
  margin: 0;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transition: opacity 0.3s, -webkit-transform 0.5s;
  transition: opacity 0.3s, transform 0.5s;
  transition: opacity 0.3s, transform 0.5s, -webkit-transform 0.5s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-info > .content.active {
  opacity: 1;
  pointer-events: all;
}

.hotspot-info > .content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  margin: 0;
  padding: 16px 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hotspot-info > .content > h3 > .close {
  background: rgb(var(--main-dark));
  margin: 0;
  padding: 0.5vw 0.7vw 0.3vw 0.7vw;
  cursor: pointer;
  pointer-events: all;
}

.hotspot-info > .content > h3 > .close > img {
  width: 2vw;
}

.hotspot-info > .content > p {
  background: #ffffff;
  width: 100%;
  height: auto;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;
  color: #000004;
  margin: 0;
  padding: 16px 16px;
}

.hotspot-info > .content > .button-link {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  margin: 0 18px 16px 18px;
  width: 10vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.83vw;
  line-height: 150%;
  color: #141414;
  text-align: center;
  cursor: pointer;
}

.hotspot-info > .content > .contact-info {
  color: #999ca1;
  margin: 0 18px 16px 18px;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 10px;
  line-height: 100%;
  cursor: pointer;
  padding: 0;
  white-space: pre-wrap;
  letter-spacing: 0.1px;
}

.hotspot-info > svg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
}

.hotspot-info > svg:hover {
  width: 60px;
}

.hotspot-info > svg * {
  -webkit-filter: none;
          filter: none;
}

.hotspot-info .hotspot {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0.9;
}

.hotspot-info .hotspot-hover {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-info .hotspot-hover.active {
  opacity: 1;
}

.hotspot-info:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-info .hotspot-oval {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-info .hotspot-oval.active {
  opacity: 1;
}

.info-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 10vh;
}

.info-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.info-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.info-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

.info-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));

  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;

  margin: 0;

  padding-left: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.info-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-hotspot-container > .mobile-content > h3 > .close > svg {
  width: 24px;
  height: 24px;
}

.info-hotspot-container > .mobile-content > h3 > .close > svg > * {
  fill: #ffffff;
}

.info-hotspot-container > .mobile-content > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  height: 100%;
  margin: 0;
  padding: 16px 16px 60px 16px;

  overflow-y: auto;

  background: #ffffff;
}

.info-hotspot-container > .mobile-content > p::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.info-hotspot-container > .mobile-content > .button-link {
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  margin: 0 4vw 2vw 4vw;
  width: 30vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 2.4;
  color: #141414;
  text-align: center;
}

.info-hotspot-container > .mobile-content > .contact-info {
  color: #999ca1;
  margin: 0 4vw 2vw 4vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 100%;
  cursor: pointer;
  padding: 0;
  white-space: pre-wrap;
  letter-spacing: 0.1px;
}

.hotspot-link {
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hotspot-link:hover {
  z-index: 11;
}

.hotspot-link .hotspot {
  position: absolute;
  opacity: 0.8;
  width: auto;
  height: 5.9vh;
  cursor: pointer;
}

.hotspot-link .hotspot.active {
  opacity: 1;
}

.hotspot-link:hover .hotspot {
  opacity: 1;
}

.hotspot-link > svg {
  position: absolute;
  width: 40px;
  height: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  /*filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));*/
}

.hotspot-link > svg:hover {
  opacity: 1;
}

.hotspot-link > svg * {
  -webkit-filter: none;
          filter: none;
}

.hotspot-link > .tooltip {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  background: #ffffff;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: #000004;
  overflow: hidden;
  padding: 8px;
  pointer-events: none;
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease,
    -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hotspot-link:hover > .tooltip,
.hotspot-link > .tooltip.hovered {
  opacity: 0.9;
  -webkit-transform: translate(25px, -50%);
          transform: translate(25px, -50%);
}

.hotspot-link > .tooltip > h6 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0 0 8px 0;
}

.hotspot-link > .tooltip > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.0015em;
  color: #464646;
  margin: 0;
  white-space: pre;
}

.hotspot-link > .arrow-container {
  width: 60px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hotspot-link > .arrow-container > .arrow {
  -webkit-animation: fade-arrow;
          animation: fade-arrow;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  opacity: 1;
}

.hotspot-link > .arrow-container > .arrow > * {
  fill: rgb(var(--main-color));
}

.hotspot-link > .arrow-container > .arrow.two {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.hotspot-link > .arrow-container > .arrow.three {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes fade-arrow {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}

@keyframes fade-arrow {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}

.hotspot-container {
  z-index: 299;
  pointer-events: none;
}

.permalink-focus {
  width: auto;
  height: 3.5vh;
  -webkit-filter: drop-shadow(0px 1.66667px 3.33333px #000000);
          filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}

.hotspot-img > .content {
  position: absolute;
  width: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0;
  margin: 0;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transition: opacity 0.3s, -webkit-transform 0.5s;
  transition: opacity 0.3s, transform 0.5s;
  transition: opacity 0.3s, transform 0.5s, -webkit-transform 0.5s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-img > .content.active {
  opacity: 1;
}

.hotspot-img > .content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  margin: 0;
  padding: 16px 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hotspot-img > svg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
}

.hotspot-img > svg:hover {
  width: 60px;
}

.hotspot-img > svg * {
  -webkit-filter: none;
          filter: none;
}

.hotspot-img > .content > .image-container {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
}

.hotspot-img > .content > .image-container > img {
  width: auto;
  height: 30.74vh;
}

.hotspot-img .content p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #141414;
  margin: 0 11px 15px 11px;
}

.hotspot-img .hotspot {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 4vh;
  opacity: 0.9;
}

.hotspot-img .hotspot-hover {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-img .hotspot-hover.active {
  opacity: 1;
}

.hotspot-img:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-img .hotspot-oval {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-img .hotspot-oval.active {
  opacity: 1;
}

.img-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.img-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.img-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.img-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.img-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  margin: 0;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-hotspot-container > .mobile-content > h3 > .close > svg {
  width: 24px;
  height: 24px;
}

.img-hotspot-container > .mobile-content > h3 > .close > svg > * {
  fill: #ffffff;
}

.img-hotspot-container > .mobile-content > .image-container {
  width: 100%;
  height: calc(100% - 48px);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-hotspot-container > .mobile-content > .image-container > img {
  height: auto;
  width: auto;
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  pointer-events: all;
}

.fullscreen-container > img {
  height: 100%;
  width: auto;
}

.fullscreen-container > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.fullscreen-container > svg > * {
  fill: #ffffff;
}

.hotspot-label.up .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotspot-label.left .content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hotspot-label.down .content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label.right .content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.hotspot-label .content h3 {
  flex: 1 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 30px;
  line-height: 35px;
  /* or 117% */

  text-align: center;

  color: rgb(var(--main-color));
  white-space: pre;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.hotspot-label .content .pointer {
  flex: 1 1;
  margin: 0;
  z-index: 99;
  display: block;
}

.hotspot-label .content .pointer img {
  width: 45px;
  height: 45px;
}

.hotspot-label.up .content .pointer img,
.hotspot-label.up > .content > .pointer > svg {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.hotspot-label.left .content .pointer img,
.hotspot-label.left > .content > .pointer > svg {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.hotspot-label.down .content .pointer img,
.hotspot-label.down > .content > .pointer > svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.hotspot-label.right .content .pointer img,
.hotspot-label.right > .content > .pointer > svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/*.hotspot-label > .content > .pointer > svg {
}*/

.hotspot-label > .content > .pointer > svg * {
  fill: rgb(var(--main-color));
  -webkit-filter: none;
          filter: none;
}

video {
  object-fit: fill;
}

.modal-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.modal-container.active {
  opacity: 1;
  pointer-events: all;
  z-index: 99;
}

.modal-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.hotspot-modal > svg.new-label {
  position: absolute;
  -webkit-transform: translate(-50%, -200%);
          transform: translate(-50%, -200%);
  width: 40px;
  height: auto;
  opacity: 1;
  cursor: auto;
  transition: all ease 0.3s;
}

.hotspot-modal > svg.new-label.hover {
  -webkit-transform: translate(-50%, -250%);
          transform: translate(-50%, -250%);
}

.hotspot-modal > svg.new-label:hover {
  width: 34px;
}

.hotspot-modal > div.new-label {
  position: absolute;
  -webkit-transform: translate(-50%, -275%) skew(-15deg);
          transform: translate(-50%, -275%) skew(-15deg);
  height: auto;
  opacity: 1;
  cursor: auto;
  font-family: Myriad Pro;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: #e84855;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
  padding: 0 5px 0 1px;
}

.hotspot-modal > svg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
  cursor: pointer;
}

.hotspot-modal > svg:hover,
.hotspot-modal > svg.hovered {
  width: 60px;
}

.hotspot-modal > svg * {
  -webkit-filter: none;
          filter: none;
}

.hotspot-modal > .hotspot {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0.9;
}

.hotspot-modal > .hotspot-hover {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-modal > .hotspot-hover.active {
  opacity: 1;
}

.hotspot-modal:hover > .hotspot-hover {
  opacity: 1;
}

.navBar-modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  height: 70vh;
  width: 65vw;
}

.modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  max-height: 95vh;
}

.navBar-modal-covilha > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-covilha > .modal-wrapper > .main-img > img {
  margin: 6vh 2vw 6vh 0;
  max-height: 100%;
}

.navBar-modal-covilha > .modal-wrapper,
.modal-covilha > .modal-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70%;
  padding: 2vw 2.5vw 0 2.5vw;
}

.main-img {
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden;
}

.information {
  position: relative;
  width: 35%;
  height: 100%;
}

.modal-covilha > h1,
.navBar-modal-covilha > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.5vw;
  line-height: 98%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navBar-modal-covilha > .modal-wrapper > .information > p,
.modal-covilha > .modal-wrapper > .information > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #141414;

  overflow-y: scroll;

  height: 100%;
  padding-right: 1vw;

  margin-top: 0;
}

.information > .logoCov {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40%;
  height: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.navBar-modal-covilha > svg,
.modal-covilha > svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.modal-covilha > h1 > svg,
.navBar-modal-covilha > h1 > svg {
  width: 2.8vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
  cursor: pointer;
}

.modal-covilha > h1 > svg > *,
.navBar-modal-covilha > h1 > svg > * {
  fill: #ffffff;
}

.mobile-modal-covilha {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-covilha > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 4vw;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-modal-covilha > h1 > svg {
  width: 4.9vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
}

.mobile-modal-covilha > .mobile-content-covilha {
  height: 88.5%;
  padding: 0 4vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-modal-covilha > .mobile-content-covilha > * {
  flex-shrink: 0;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text {
  flex: 1 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-modal-img {
  flex: 1 1;
  width: 100%;
  height: 80%;
  margin-bottom: 0;
  overflow: hidden;
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-modal-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mobile-modal-covilha > .mobile-content-covilha > .mobile-text > .logoCov {
  width: 40%;
  height: auto;
  margin-bottom: 2vw;
}

.mobile-modal-covilha > .mobile-content-covilha > svg {
  width: 100%;
  height: auto;
}

.navBar-modal-det,
.modal-det {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 99;
  transition: width 0.5s ease, height 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navBar-modal-det > .head,
.modal-det > .head {
  width: 100%;
  height: 4.44vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navBar-modal-det > .head > h1,
.modal-det > .head > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.04vw;
  line-height: 100%;
  display: flex;
  align-items: center;

  /* white */

  color: #ffffff;
  margin: 0;
  background: rgb(var(--main-color));
  width: 100%;
  padding: 1.49vh 0.83vw;
}

.navBar-modal-det > .head > .svg-container {
  width: 2.5vw;
  height: 4.44vh;
  background: rgb(var(--main-dark));
}

.navBar-modal-det > .head > .svg-container > svg {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.navBar-modal-det > .head > .svg-container > svg > * {
  fill: #ffffff;
}

.navBar-modal-det > .content {
  background: #ffffff;
  padding: 1.49vh 0.83vw;
}

.navBar-modal-det > .content > .main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.navBar-modal-det > .content > .main-container > img {
  max-height: 55.56vh;
  width: auto;
}

.navBar-modal-det > .content > .main-container > .details {
  width: 18.33vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
  max-height: 55.56vh;
  overflow: scroll;
}

.navBar-modal-det > .content > .main-container > .details::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.navBar-modal-det > .content > .main-container > .details > .chars > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 8px 0 0 0;
}

.navBar-modal-det > .content > .main-container > .details > .chars > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 16px 0 32px 0;
}

.navBar-modal-det > .content > .main-container > .details > .list > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul {
  margin: 16px 0 32px 0;
  padding: 0 16px;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul > li {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin-top: 8px;
}

.navBar-modal-det > .content > .main-container > .details > .list > ul > li::marker {
  color: #000004;
  font-size: 0.5em;
}

.navBar-modal-det > .content > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-top: 16px;
}

.modal-det > .content > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-x: scroll;
  padding-top: 16px;
}

.navBar-modal-det > .content > .selector > .selector-img-container,
.modal-det > .content > .selector > .selector-img-container {
  position: relative;
  opacity: 0.5;
  height: 7.78vh;
  width: 6.56vw;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin-right: 16px;
}

.navBar-modal-det > .content > .selector > .selector-img-container > .selector-img,
.modal-det > .content > .selector > .selector-img-container > .selector-img {
  width: 100%;
  height: 100%;
}

.navBar-modal-det > .content > .selector > .selector-img-container:hover {
  opacity: 1;
}

.navBar-modal-det > .content > .selector > .selector-img-container.active {
  opacity: 1;
}

.navBar-modal-det > .content > .selector > .selector-img-container > .border {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.navBar-modal-det > .content > .selector > .selector-img-container.active > .border {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
}

.mobile-modal-det {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-det > .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-modal-det > .header > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 16px 0 16px;
  color: #ffffff;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
}

.mobile-modal-det > .header > .svg-container {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.mobile-modal-det > .header > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-det > .header > .svg-container > svg > * {
  fill: #ffffff;
}

.mobile-modal-det > .footer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background: #ffffff;
  border-top: 1px solid #808080;
}

.mobile-modal-det > .footer > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-modal-det > .footer > svg > * {
  fill: #808080;
}

.mobile-modal-det > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-det > .image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: hidden;
}

.mobile-modal-det > .image > .container {
  width: 80%;
  height: 100%;
}

.mobile-modal-det > .image > .container > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.mobile-modal-det > .image > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-det > .image > svg > * {
  fill: #808080;
}

.mobile-modal-det > .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

.mobile-modal-det > .info::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-det > .info > .chars > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 0 0 0 0;
  width: 100%;
}

.mobile-modal-det > .info > .chars > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin: 16px 0 32px 0;
  width: 100%;
}

.mobile-modal-det > .info > .list {
  width: 100%;
}

.mobile-modal-det > .info > .list > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0015em;
  color: #000004;
  margin: 0;
  width: 100%;
}

.mobile-modal-det > .info > .list > ul {
  margin: 16px 0 32px 0;
  padding: 0 16px;
}

.mobile-modal-det > .info > .list > ul > li {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.005em;
  color: #000004;
  margin-top: 8px;
}

.mobile-modal-det > .info > .list > ul > li::marker {
  color: #000004;
  font-size: 0.5em;
}

.mobile-modal-det > .collection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

.mobile-modal-det > .collection::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-det > .collection > .selector-img-container {
  width: 30%;
  height: 100px;
}

.mobile-modal-det > .collection > .selector-img-container > img {
  width: 100%;
  height: 100%;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000004;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  pointer-events: all;
  padding: 0 16px;
}

.full > svg {
  width: 24px;
  height: 24px;
}

.full > svg > * {
  fill: #808080;
}

.full > img {
  height: 100%;
  max-width: 90%;
}

.full > .full-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.navBar-modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  z-index: 99;
  transition: width 0.5s ease;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5.89px 11.78px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 99;
  transition: width 0.5s ease;
  max-height: 95vh;
  width: 45vw;
}

.navBar-modal-expo > .modal-wrapper > img {
  max-height: 55vh;
  margin-right: 2.5vw;
}

.navBar-modal-expo > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-expo > .modal-wrapper > .main-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navBar-modal-expo > .modal-wrapper,
.modal-expo > .modal-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 2vw 2.5vw 0 2.5vw;
}

.modal-expo > .modal-wrapper > .main-img,
.navBar-modal-expo > .modal-wrapper > .main-img {
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden;
  margin-right: 2.5vw;
}

.modal-expo > .modal-wrapper > .information,
.navBar-modal-expo > .modal-wrapper > .information {
  position: relative;
  min-width: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.modal-expo > h1,
.navBar-modal-expo > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 98%;
  letter-spacing: 0.1vw;

  width: 100%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translate(0, -10%);
          transform: translate(0, -10%);
}

.navBar-modal-expo > .modal-wrapper > .information > h1,
.modal-expo > .modal-wrapper > .information > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 0;
  margin-bottom: 0.5vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > p,
.modal-expo > .modal-wrapper > .information > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 1.2vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 0;
  margin-bottom: 0.5vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > h2,
.modal-expo > .modal-wrapper > .information > h2 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: #4d5057;

  overflow-y: scroll;

  margin-top: 1vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > h2 > span,
.modal-expo > .modal-wrapper > .information > h2 > span {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.4vw;
  color: rgb(var(--main-color));

  overflow-y: scroll;

  margin-top: 1vw;
  letter-spacing: 0.1vw;
}

.navBar-modal-expo > .modal-wrapper > .information > div,
.modal-expo > .modal-wrapper > .information > div {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 3vw;
  color: rgb(var(--main-color));

  border: 2px solid rgb(var(--main-color));
  width: 80%;

  text-align: center;
  vertical-align: middle;

  margin-top: 1vw;
  cursor: pointer;
  letter-spacing: 0.1vw;
}

.modal-expo > .gallery,
.navBar-modal-expo > .gallery {
  width: 100%;
  height: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1vw 2.5vw 0 2.5vw;
  overflow-x: scroll;
}

.modal-expo > .gallery > img,
.navBar-modal-expo > .gallery > img {
  width: 4vw;
  height: auto;
  margin-right: 1vw;
  opacity: 0.5;
  cursor: pointer;
  border: 3px solid transparent;
}

.modal-expo > .gallery > img.selected,
.navBar-modal-expo > .gallery > img.selected {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
  background-color: rgb(var(--main-color));
}

.modal-expo > .footer,
.navBar-modal-expo > .footer {
  width: 100%;
  font-family: 'Merriweather Sans', sans-serif;
  font-style: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 1.1vw;
  /* identical to box height */

  color: #999ca2;

  padding-left: 2.5vw;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;

  white-space: pre-wrap;
}

.modal-expo > .footer > p,
.navBar-modal-expo > .footer > p {
  display: inline-block;
  cursor: pointer;
}

.information > .logoCov {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40%;
  height: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.navBar-modal-expo > svg,
.modal-expo > svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.modal-expo > h1 > svg,
.navBar-modal-expo > h1 > svg {
  width: 2.8vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
  cursor: pointer;
}

.mobile-modal-expo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-expo > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #ffffff;

  background: rgb(var(--main-color));
  border-radius: 0px;

  margin: 0;
  padding: 0 0 0 4vw;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-modal-expo > h1 > svg {
  width: 4.9vw;
  height: auto;
  background: rgb(var(--main-dark));
  border-radius: 0px;
  padding: 0.5vw;
}

.mobile-modal-expo > .mobile-content-expo {
  height: 88.5%;
  width: 100%;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-modal-expo > .mobile-content-expo > * {
  flex-shrink: 0;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text {
  flex: 1 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.4vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h2 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 2vw;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > h2 > span {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.2vw;
  line-height: 144%;
  color: rgb(var(--main-color));
  margin-top: 2vw;
  margin-bottom: 2vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > div {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 2.4;
  color: rgb(var(--main-color));

  border: 2px solid rgb(var(--main-color));
  width: 50%;

  text-align: center;
  vertical-align: middle;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 144%;
  color: #000000;
  margin-top: 0;
  margin-bottom: 1vw;
  padding-right: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-modal-img {
  flex: 1 1;
  width: 100%;
  height: 80%;
  margin-bottom: 0;
  overflow: hidden;
  margin: 0 4vw;
}

.mobile-modal-expo > .mobile-content-expo > .mobile-modal-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mobile-modal-expo > .mobile-content-expo > .mobile-text > .logoCov {
  width: 40%;
  height: auto;
  margin-bottom: 2vw;
}

.mobile-modal-expo > .mobile-content-expo > svg {
  width: 6vw;
  height: auto;
}

.mobile-modal-expo > .mobile-content-expo > svg > * {
  fill: #949494;
}

.navBar-modal,
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navBar-modal > img {
  max-height: 58.56vh;
}

.modal > img {
  max-height: 58.56vh;
}

.navBar-modal > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.modal > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-x: scroll;
}

.navBar-modal > .selector > .selector-img-container,
.modal > .selector > .selector-img-container {
  position: relative;
  opacity: 0.5;
  height: 7.78vh;
  width: 6.56vw;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin-right: 16px;
}

.navBar-modal > .selector > .selector-img-container > .selector-img,
.modal > .selector > .selector-img-container > .selector-img {
  width: 100%;
  height: 100%;
}

.selector > .selector-img-container:hover {
  opacity: 1;
}

.selector > .selector-img-container.active {
  opacity: 1;
}

.selector > .selector-img-container > .border {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.selector > .selector-img-container.active > .border {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
}

.navBar-modal > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 16px 0 24px 0;
}

.modal > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.04vw;
  line-height: 100%;
  color: #000004;
  margin: 1.48vh 0 2.22vh 0;
}

.mobile-modal-galeria {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-modal-galeria > .main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 36px);
}

.mobile-modal-galeria > .main > img {
  height: 100%;
  width: auto;
}

.mobile-modal-galeria > .main > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-galeria > .main > svg > * {
  fill: #949494;
}

.mobile-modal-galeria > .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
}

.mobile-modal-galeria > .footer > svg {
  width: 24px;
  margin-right: 16px;
}

.mobile-modal-galeria > .footer > svg > * {
  fill: #949494;
}

.mobile-modal-galeria > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-galeria > .footer > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 0;
}

.mobile-modal-galeria > .mobile-selector {
  height: calc(100% - 36px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
}

.mobile-modal-galeria > .mobile-selector::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-galeria > .mobile-selector > .selector-img-container {
  height: 100px;
  width: 30%;
}

.mobile-modal-galeria > .mobile-selector > .selector-img-container > .selector-img {
  width: 100%;
  height: 100%;
}

.modal-text {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  overflow: hidden;
}

.modal-text > .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-text > .head > h1 {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  background: rgb(var(--main-color));
}

.modal-text > .head > .svg-container {
  width: 48px;
  height: 48px;
  background: rgb(var(--main-dark));
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-text > .head > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.modal-text > .head > .svg-container > svg > * {
  fill: #ffffff;
}

.modal-text > .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  padding: 16px;
}

.modal-text > .content > img {
  max-width: 40vw;
  max-height: 60vh;
}

.modal-text > .content > p {
  width: 336px;
  height: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  padding-left: 16px;
  margin: 0;
}

.mobile-modal-text {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-text > .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-modal-text > .header > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 16px 0 16px;
  color: #ffffff;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
}

.mobile-modal-text > .header > .svg-container {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.mobile-modal-text > .header > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-text > .header > .svg-container > svg > * {
  fill: #ffffff;
}

.mobile-modal-text > .footer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background: #ffffff;
  border-top: 1px solid #808080;
}

.mobile-modal-text > .footer > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-modal-text > .footer > svg > * {
  fill: #808080;
}

.mobile-modal-text > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-text > .image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: hidden;
}

.mobile-modal-text > .image > .container {
  width: 100%;
  height: 100%;
}

.mobile-modal-text > .image > .container > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.mobile-modal-text > .image > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-text > .image > svg > * {
  fill: #808080;
}

.mobile-modal-text > .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

.mobile-modal-text > .info::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-text > .info > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  margin: 0;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000004;
  z-index: 99;
  pointer-events: all;
}

.full > svg {
  width: 24px;
  height: 24px;
}

.full > svg > * {
  fill: #808080;
}

.full > img {
  height: 100%;
  max-width: 90%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.full > .full-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.modal-video {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 71.35vw;
  height: 75.65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 16px;
}

.modal-video > iframe {
  width: 100%;
  height: 100%;
}

.modal-video > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 16px 0 0 0;
  padding: 0;
}

.mobile-modal-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 16px;
}

.mobile-modal-video > iframe {
  width: 100%;
  height: 100%;
}

.mobile-modal-video > p {
  width: 100%;
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #000004;
  margin: 16px 0 0 0;
}

.vg-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vg-modal > iframe {
  width: 60vw;
  height: 60vh;
}

.vg-modal > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* davys-grey-100 */

  color: #000004;
  margin: 16px 0 0 0;
  padding: 0;
  width: 100%;
}

.vg-modal > .thumbs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 84px;
  width: 100%;
  margin-top: 16px;
}

.vg-modal > .thumbs-container > img {
  height: 100%;
  width: auto;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 16px;
}

.vg-modal > .thumbs-container > img:first-child {
  margin-left: 0;
}

.vg-modal > .thumbs-container > img.selected {
  opacity: 1;
}

/*mobile*/
.vg-modal-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  width: calc(50vw + 116px);
  height: 65vh;
  overflow: hidden;
}

.vg-modal-mobile > iframe {
  width: 50vw;
  height: 55vh;
  position: absolute;
  top: 8px;
  left: 8px;
}

.vg-modal-mobile > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000004;
  margin: 8px 0 0 0;
  padding: 0;
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.vg-modal-mobile > .thumbs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 100%;
  transition: height 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 8px 8px 0;
  overflow: scroll;
}

.vg-modal-mobile > .thumbs-container > img {
  height: auto;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}

.vg-modal-mobile > .thumbs-container > img:first-child {
  margin-left: 0;
}

.vg-modal-mobile > .thumbs-container > img.selected {
  opacity: 1;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url(https://covilha.alive.vision/static/media/MYRIADPRO-REGULAR.d75d7f70.OTF) format('truetype');
}

.viewer-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  z-index: 99;
  transition: width 0.5s ease, height 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  width: 75vw;
  height: 40vw;
}

.viewer-modal > .viewer-title {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgb(var(--main-color));
}

.viewer-modal > .viewer-title > .close-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.viewer-modal > .viewer-body > .specs-container > .specs-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
  margin-left: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-btns > svg > * {
  fill: rgb(var(--main-color));
}

.viewer-modal > .viewer-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container {
  height: 100%;
  width: 34%;
  background: #ffffff;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.viewer-modal > .viewer-body > .specs-container > .specs-head > .head-title > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body {
  padding: 16px 0 0 0;
  overflow: scroll;
  max-height: 90%;
}

@media only screen and (max-width: 1400px) {
  .viewer-modal > .viewer-body > .specs-container > .specs-body {
    padding: 16px 0 0 0;
    overflow: scroll;
    max-height: 85%;
  }
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .specs-description {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1.3vw;
  letter-spacing: 0.005em;
  color: #3e3f40;
  max-width: 100%;
  margin: 0;
  padding: 0 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > h2 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2vw;
  line-height: 1.2vw;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 32px 16px 16px 16px;
  padding: 0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.3vw;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0 0 0 16px;
  width: 40%;
}

.viewer-modal > .viewer-body > .specs-container > .specs-body > .feature-container > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 160%;
  white-space: pre-wrap;
  letter-spacing: 0.005em;
  color: #3e3f40;
  margin: 0;
  padding: 0;
  width: 60%;
  padding-right: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > .border {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(var(--main-color));
  border-radius: 50%;
  cursor: pointer;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > .border > svg {
  width: 24px;
  height: 24px;
}

.viewer-modal > .viewer-body > .specs-container > .specs-buttons > .specs-button > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 106%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgb(var(--main-color));
}

.viewer-modal
  > .viewer-body
  > .specs-container
  > .specs-buttons
  > .specs-button
  > .border
  > svg
  > * {
  fill: rgb(var(--main-color));
}

.viewer-modal > .viewer-body > .viewer-container {
  width: 66%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cdced0;
}

.viewer-modal > .viewer-body > .viewer-container > .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(40, 40, 40, 0.7);
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri {
  opacity: 0;
  fill: #ffffff;
  -webkit-animation: fade ease-in 3s;
          animation: fade ease-in 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg {
  width: 100px;
  height: auto;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri.two {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > svg > .tri.three {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

.viewer-modal > .viewer-body > .viewer-container > .loader > .pb {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
  margin-top: 16px;
  width: 100px;
  text-align: left;
}

.viewer-modal > .viewer-body > .viewer-container > .loader > .av-logo {
  width: 100px;
  height: auto;
}

.viewer-modal > .viewer-body > .viewer-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
}

.viewer-modal > .viewer-body > .viewer-container > img.bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  height: auto;
  width: 100%;
  pointer-events: none;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer3d {
  display: flex;
  align-items: center;
  justify-content: center;
  /*height: 100% !important;
  width: 100% !important;
  /*background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(120, 120, 120, 1) 0%,
    rgba(80, 80, 80, 1) 100%
  );*/
  /*background: url('/public/assets/img/viewer_bg.png');*/
}

/*.viewer-modal > .viewer-body > .viewer-container > .viewer3d > canvas {
  width: 100% !important;
  height: 100% !important;
}*/

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
  cursor: pointer;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-toolbar > svg > * {
  fill: #ffffff;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
  cursor: pointer;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > svg > * {
  fill: #ffffff;
}

.viewer-modal > .viewer-body > .viewer-container > .viewer-perspectives > .perspective-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 3.2px #000000;
  margin-left: 16px;
}

.tutorial-screen {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tutorial-screen > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
  white-space: pre-wrap;
  text-shadow: 0px 1px 4px #000000;
}

.head-title > .new-label {
  -webkit-transform: skew(-15deg);
          transform: skew(-15deg);
  height: auto;
  opacity: 1;
  cursor: auto;
  font-family: Myriad Pro;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: #e84855;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
  padding: 0 5px 0 1px;
  margin: 4px 0 0 4px;
}

/*mobile*/
.viewer-modal-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60vw;
  height: 60vh;
}

.viewer-modal-mobile > .viewer-container-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cdced0;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
}

.viewer-modal-mobile > .viewer-info-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  transition: height 0.3s ease-in-out;
  background: #ffffff;
  z-index: 1;
  overflow: hidden;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > svg.arrow {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > svg.arrow > * {
  fill: rgb(var(--main-color));
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 64px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > h1 > svg.new {
  width: 24px;
  height: auto;
  margin: 0 0 4px 0;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > .header-btns-mobile {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer-modal-mobile > .viewer-info-mobile > .header-mobile > .header-btns-mobile > svg {
  margin-right: 20px;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(40, 40, 40, 0.7);
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri {
  opacity: 0;
  fill: #ffffff;
  -webkit-animation: fade ease-in 3s;
          animation: fade ease-in 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg {
  width: 60px;
  height: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri.two {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > svg > .tri.three {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > .pb {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  text-transform: lowercase;
  color: #ffffff;
  z-index: 1;
  margin-top: 16px;
  width: 100px;
  text-align: left;
}

.viewer-modal-mobile > .viewer-container-mobile > .loader > .av-logo {
  width: 100px;
  height: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .tutorial-screen-mobile {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewer-modal-mobile > .viewer-container-mobile > .bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile {
  height: 75%;
  overflow: scroll;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .specs-description {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #3e3f40;
  max-width: 100%;
  margin: 0;
  padding: 0 20px;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > h2 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 16px 8px 8px 8px;
  padding: 0;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid #d0d0d0;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #282b31;
  margin: 0;
  padding: 0 0 0 20px;
  width: 50%;
}

.viewer-modal-mobile > .viewer-info-mobile > .info-mobile > .feature-container > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  white-space: pre-wrap;
  letter-spacing: 0.005em;
  color: #3e3f40;
  margin: 0;
  padding: 0;
  width: 50%;
  padding-right: 20px;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen > .viewer-toolbar {
  top: 16px;
  bottom: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  -webkit-filter: drop-shadow(0px 1.6px 1.2px #000000);
          filter: drop-shadow(0px 1.6px 1.2px #000000);
  cursor: pointer;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-toolbar > svg > * {
  fill: #ffffff;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.viewer-modal-mobile > .viewer-container-mobile.fullscreen > .viewer-perspectives {
  top: 16px;
  bottom: auto;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives > svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  -webkit-filter: drop-shadow(0px 1.6px 1.2px #000000);
          filter: drop-shadow(0px 1.6px 1.2px #000000);
  cursor: pointer;
}

.viewer-modal-mobile > .viewer-container-mobile > .viewer-perspectives > svg > * {
  fill: #ffffff;
}

.nadirHS {
  visibility: visible;
  width: 800px;
  height: 800px;
  background: #000;
  border-radius: 50%;
}

#nadirHS {
  border: 4px green solid;
  border-radius: 50%;
}

.hotspot-pa .content {
  position: absolute;
  width: 25vw;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -45px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -45px, 0);
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-pa .content.active {
  opacity: 0.9;
}

.hotspot-pa .content h3 {
  background: rgb(var(--main-color));
  border-radius: 0px;
  margin: 0;
  padding: 10px 18px 12px 18px;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}

.hotspot-pa .content p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #141414;
  margin: 0;
  padding: 13px 18px 16px 18px;
}

.hotspot-pa > svg.hotspot {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 4.2vh;
  opacity: 0.9;
  -webkit-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
  cursor: pointer;
}

.hotspot-pa > svg.hotspot *,
.hotspot-pa > .hotspot-hover * {
  -webkit-filter: none;
          filter: none;
}

.hotspot-pa .hotspot-hover {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 5.9vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  -webkit-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
}

.hotspot-pa .hotspot-hover.active {
  opacity: 1;
}

.hotspot-pa:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-pa .hotspot-oval {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-pa .hotspot-oval.active {
  opacity: 1;
}

.pa-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 10vh;
}

.pa-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.pa-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.pa-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.pa-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));
  border-radius: 10px 10px 0px 0px;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 3vw;
  line-height: 3.2vw;
  color: #ffffff;

  margin: 0;
  padding: 0 0 0 2.5vw;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pa-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  border-radius: 0px 10px 0px 0px;

  margin: 0;
  padding: 1.2vw 1.5vw;
}

.pa-hotspot-container > .mobile-content > svg {
  width: 6vw;
}

.pa-hotspot-container > .mobile-content > h3 > .close > img {
  width: 4vw;
  height: auto;
}

.pa-hotspot-container > .mobile-content > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;
  /* or 144% */

  color: #141414;
  height: 73%;
  margin: 0;
  padding: 2vw 4vw;

  overflow-y: auto;
}

.hotspot-article > svg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 35px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
  cursor: pointer;
}

.hotspot-article > svg:hover {
  width: 60px;
}

.hotspot-article > svg * {
  -webkit-filter: none;
          filter: none;
}

div {
  position: relative;
}

.explorer-container.pa * {
  cursor: url(https://covilha.alive.vision/static/media/poin-ask-pointer.a69b3bfe.svg), auto;
}

@font-face {
  font-family: 'Merriweather Sans', sans-serif;
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot');
  src: url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.svg#Proxima Nova') format('svg');
}

@font-face {
  font-family: 'Merriweather Sans' T;
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot');
  src: url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/0d54578761902bda6813941de15e8f84.svg#Proxima Nova Thin')
      format('svg');
}

.topNameContainer {
  position: fixed;
  top: 4.63vh;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 88;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topNameContainer > h1 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.0025em;

  color: #ffffff;

  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);

  margin: 0 0 16px 0;
}

.topNameContainer > h2 {
  font-family: 'Mukta Vaani', sans-serif;
  font-size: 1vw;
  line-height: 110%;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  margin: 0;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
}

.feedback-container {
  position: fixed;
  bottom: 61px;
  left: 16px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding: 0 14px;
}

.feedback-container > svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.feedback-container > svg > * {
  fill: #000004;
}

.feedback-container > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  display: flex;
  align-items: center;

  /* davys-grey-100 */

  color: #000004;
}

.main-bar {
  position: fixed;
  right: 2.4vw;
  top: 90.93vh;
  width: auto;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 100;
  padding: 0 0 0 0.83vw;
  opacity: 0.65;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-bar.thumbnails {
  top: 79.63vh;
}

.main-bar:hover {
  opacity: 1;
}

.main-bar > p {
  flex: 5 1;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 1vw;

  color: #101010;
}

.main-bar > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-bar svg {
  flex: 1 1;

  width: 24px;
  height: 24px;

  margin: 12px 16px 12px 0;

  cursor: pointer;
}

.main-bar svg > * {
  fill: #101010;
}

.progress-bar {
  position: fixed;
  right: 2.4vw;
  top: 89vh;
  width: 134px;
  height: 8px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 100;
}

.progress-bar > .progress {
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  transition: width 1s ease;
}

.thumbnails-menu {
  position: fixed;
  height: 13.7vh;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  overflow-x: auto;
}

.thumbnails-menu > .selector {
  flex: 0 0 8.44vw;
  height: 10vh;
  border-radius: 5px;
  margin-right: 16px;
  cursor: pointer;
}

.thumbnails-menu > .gradient-thumbnail > .selector {
  width: 100%;
  height: 100%;
  margin-right: 16px;
}

.thumbnails-menu > .gradient-thumbnail > .selector.selected {
  border: 3px solid rgb(var(--main-color));
  box-sizing: border-box;
}

.thumbnails-menu > .gradient-thumbnail {
  flex: 0 0 8.44vw;
  height: 10vh;
  margin-right: 16px;
  cursor: pointer;
}

.thumbnails-menu > .gradient-thumbnail > .gradient {
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  padding: 0.57vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.mobile-navBar {
  width: 100vw;
  height: 45px;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}

.mobile-navBar > p {
  flex: 10 1;
  font-family: 'Mukta Vaani';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin: 0;
}

.mobile-navBar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  pointer-events: all;
}

.mobile-navBar > svg > * {
  fill: #949494;
}

.mobile-navBar > svg.share-icon > g {
  -webkit-filter: none;
          filter: none;
}

.mobile-navBar > svg.share-icon > g > path {
  fill: #949494;
}

.mobile-navBar > svg.mapActive > *,
.mobile-navBar > svg.thumbnails > *,
.mobile-navBar > svg.infoActive > * {
  fill: rgb(var(--main-color));
}

.mobile-thumbnails-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 45px);
  background: #ffffff;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  z-index: 399;
}

.mobile-thumbnails-menu > .selector {
  width: 20%;
  height: 20vh;
  margin-left: 1.2vw;
  cursor: pointer;
  border-radius: 10px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .selector {
  width: 100%;
  height: 100%;
}

.mobile-thumbnails-menu > .gradient-thumbnail {
  height: 100px;
  width: 30%;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .gradient {
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #ffffff;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 16px;
}

.mobile-thumbnails-menu > .gradient-thumbnail > .selector.selected {
  border: 3px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 10px;
}

.mobile-slideshow-menu {
  width: 100%;
  height: 45px;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.mobile-slideshow-menu > p {
  flex: 10 1;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* black */

  color: #101010;
  margin: 0;
}

.mobile-slideshow-menu > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-slideshow-menu > svg > * {
  fill: #949494;
}

.mobile-slideshow-menu > svg.close {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-slideshow-menu > svg.close > * {
  fill: #101010;
}

.mobile-slideshow-menu > svg.pause > * {
  fill: rgb(var(--main-color));
}

.mobile-slideshow-menu > .mobile-progress-bar {
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 0;
  position: absolute;
  top: -4px;
}

.mobile-slideshow-menu > .mobile-progress-bar > .progress {
  background: rgb(var(--main-color));
  height: 100%;
  transition: width 1s ease;
}

.toolbar {
  position: fixed;
  z-index: 296;
  top: 91.94vh;
  left: 3.23vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toolbar > * {
  margin-right: 24px;
}

.mobile-shared {
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.3vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  transition: all 0.5s ease;
  z-index: 200;
}

.mobile-timelapse-menu {
  width: 100vw;
  height: 6vw;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-timelapse-menu > svg {
  width: 4.5vw;
  height: auto;
  margin-right: 2vw;
}

.mobile-timelapse-menu > svg > * {
  fill: #949494;
}

.mobile-timelapse-menu > svg.close {
  width: 4vw;
  height: auto;
  margin-right: 2vw;
}

.mobile-timelapse-menu > svg.close > * {
  fill: #101010;
}

.mobile-timelapse-menu > svg.pause > * {
  fill: rgb(var(--main-color));
}

.mobile-timelapse-menu > .line {
  width: 72%;
  border: 0;
  background-color: #101010;
  height: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  margin-right: 2vw;
}

.mobile-timelapse-menu > .line > .time-selector {
  width: 1.6vw;
  height: 1.6vw;
  background: #ffffff;
  border: 1px solid #101010;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-timelapse-menu > .line > .time-selector.selected {
  width: 3vw;
  height: 3vw;
  border: 2px solid #101010;
}

.mobile-timelapse-menu > .line > .time-selector > p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -310%);
          transform: translate(-50%, -310%);
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 100%;
  /* identical to box height, or 100% */

  /* black */

  color: #101010;

  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.mobile-timelapse-menu > .line > .time-selector.selected > p {
  opacity: 1;
}

.pa {
  position: relative;
}

.pa > svg {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 5vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  cursor: pointer !important;
}

.pa > svg.back-pa {
  opacity: 0;
  margin-left: -0.5px;
  cursor: pointer !important;
}

.pa > svg.back-pa * {
  cursor: pointer !important;
}

.pa > svg.back-pa:hover {
  opacity: 1;
}

.pa > svg.svg-pa {
  width: auto;
  height: 5.6vh;
  z-index: 0;
  -webkit-filter: drop-shadow(0px 1.66667px 3.33333px #000000);
          filter: drop-shadow(0px 1.66667px 3.33333px #000000);
}

.pa > div > svg.close-pa {
  position: fixed;
  right: 5vw;
  top: 7vh;
  background: #101010;
  opacity: 0.6;
  border-radius: 50%;
  cursor: pointer;
}

.pa > div > svg.close-pa * {
  cursor: pointer;
}

.pa > div > .info-pa {
  position: fixed;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  white-space: pre-line;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.6vw;
  line-height: 117%;
  /* or 117% */

  text-align: center;

  /* White */

  color: #ffffff;
  /* shadow-text */

  text-shadow: 0px 1px 5px #000000;
}

.mobile-pa-bar {
  width: 100vw;
  height: 6vw;
  background: #ffffff;
  opacity: 0.9;
  position: fixed;
  bottom: 0;
  z-index: 401;
  padding: 0 0 0 2vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-pa-bar > .mobile-close-pa {
  width: 4vw;
  height: auto;
  margin-right: 4vw;
  z-index: 1;
}

.mobile-pa-bar > .mobile-close-pa > * {
  fill: #101010;
}

.mobile-pa-bar > .mobile-info-pa {
  position: absolute;
  width: 100%;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 144%;

  color: #101010;
  margin: 0;
  text-align: center;
}

.pano-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pano-info > .pano-info-container {
  position: fixed;
  bottom: 6.11vh;
  left: 3.23vw;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vw;
}

.pano-info > .pano-info-container > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  text-shadow: 0px 1px 5px #000000;
  margin: 0;
}

.pano-info > .pano-info-container > .know-more-btn {
  box-sizing: border-box;
  padding: 1vw 2vw;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 89%;

  text-align: center;

  color: rgb(var(--main-color));
  background: #ffffff;

  cursor: pointer;
}

.pano-info > .pano-info-container > .know-more-btn:hover {
  background: rgb(var(--main-color));
  color: #ffffff;
}

.metadata,
.metadata > .author,
.metadata > .date,
.metadata > .coords {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.metadata svg {
  width: 34px;
  height: 34px;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
  margin-right: 5px;
}

.metadata svg > * {
  fill: #ffffff;
}

.metadata svg * {
  -webkit-filter: none;
          filter: none;
}

.mobile-metadata.metadata svg {
  width: 3vw;
  height: auto;
}

.metadata > .author > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-shadow: 0px 1px 5px #000000;
  margin-right: 20px;
}

.metadata > .date > p,
.metadata > .coords > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #ffffff;

  text-shadow: 0px 1px 5px #000000;
  margin-right: 20px;
}

.mobile-metadata.metadata > .author > p,
.mobile-metadata.metadata > .date > p,
.mobile-metadata.metadata > .coords > p {
  font-size: 2vw;
}

.pano-info > .pano-info-container > .metadata > .author > p > b {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: normal;
}

.pano-info > .pano-info-btn > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 24px;
  width: 24px;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.pano-info > .pano-info-btn > svg * {
  -webkit-filter: none;
          filter: none;
}

.pano-info > .pano-info-btn {
  width: 24px;
  height: 24px;
}

.pano-info > .pano-info-btn > .p-info {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.pano-info > .pano-info-btn > .p-info > * {
  fill: #ffffff;
}

.pano-info > .pano-info-btn > .p-info * {
  -webkit-filter: none;
          filter: none;
}

.pano-info > .pano-info-btn > .arrow-div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pano-info > .pano-info-btn > .arrow-div > .info-tooltip {
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.pano-info > .pano-info-btn > .arrow-div > .arrow-down {
  width: 0;
  height: 0;
  border-left: 0.5vw solid transparent;
  border-right: 0.5vw solid transparent;
  border-top: 0.5vw solid #4a4a4a;
}

.know-more-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(72, 63, 50, 0.95);
  z-index: 200;
}

.know-more-panel > svg {
  position: absolute;
  top: 3vw;
  right: 5vw;
  width: 5vw;
  cursor: pointer;
}

.know-more-panel > svg > * {
  fill: #ffffff;
}

.know-more-panel > .know-more-info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40%;
}

.know-more-panel > .know-more-info > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 83%;
  /* identical to box height, or 83% */

  letter-spacing: -0.01em;

  /* White */

  color: #ffffff;
}

.know-more-panel > .know-more-info > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.1vw;
  line-height: 142%;
  /* or 142% */

  color: #ffffff;

  text-shadow: 0px 1px 2px #000000;
}

.mobile-know-more-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(72, 63, 50, 0.95);
  z-index: 350;
}

.mobile-know-more-panel > svg {
  position: absolute;
  right: 2vw;
  width: 6vw;
  z-index: 1;
}

.mobile-know-more-panel > .mobile-know-more-info {
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15vw;
  z-index: 0;
}

.mobile-know-more-panel > .mobile-know-more-info > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 4.5vw;
  line-height: 111%;
  /* or 111% */

  letter-spacing: -0.01em;

  /* White */

  color: #ffffff;
  margin-bottom: 0;
}

.mobile-know-more-panel > .mobile-know-more-info > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vw;
  line-height: 189%;

  color: #ffffff;

  overflow: auto;

  height: 45vh;
  padding-right: 2vw;
}

.share > .shareButton > path {
  fill: #ffffff;
}

.share {
  width: 24px;
  height: 24px;
}

.share > .shareButton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  /*filter: drop-shadow(0px 1.66667px 3.33333px #000000);*/
  z-index: 1;
  position: relative;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.share > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: auto;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.share > svg * {
  -webkit-filter: none;
          filter: none;
}

.shared {
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  transition: all 0.5s ease;
}

.share > .arrow-div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share > .arrow-div > .info-tooltip {
  background: #4a4a4a;
  border-radius: 5px;
  padding: 6px 2vw;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.share > .arrow-div > .arrow-down {
  width: 0;
  height: 0;
  border-left: 0.5vw solid transparent;
  border-right: 0.5vw solid transparent;
  border-top: 0.5vw solid #4a4a4a;
}

.timelapse-btn > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: auto;
  height: 7vh;
  cursor: pointer;
  z-index: 1;
  -webkit-filter: drop-shadow(0px 1.66667px 3.33333px #000000);
          filter: drop-shadow(0px 1.66667px 3.33333px #000000);
  cursor: pointer !important;
}

.timelapse-btn > svg.btn-background {
  z-index: 0;
  transition: opacity 0.2s ease;
  height: 5vh;
  width: auto;
}

.timelapse-bar {
  position: fixed;
  left: 6vw;
  bottom: 12vh;

  width: auto;
  height: 4vw;
  padding: 1.4vw 1.2vw 0.2vw 1.2vw;

  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transition: opacity 0.3s ease;
}

.timelapse-bar > svg {
  width: 1.8vw;
  height: auto;

  cursor: pointer;
}

.timelapse-bar > svg > * {
  fill: #101010;
}

.timelapse-bar > .line {
  width: 12vw;
  border: 0;
  background-color: #101010;
  height: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}

.timelapse-bar > .line > .time-selector {
  width: 0.8vw;
  height: 0.8vw;
  background: #ffffff;
  border: 1px solid #101010;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.timelapse-bar > .line > .time-selector.selected {
  width: 1.5vw;
  height: 1.5vw;
  border: 2px solid #101010;
}

.timelapse-bar > .line > .time-selector > p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -310%);
          transform: translate(-50%, -310%);
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 100%;
  /* identical to box height, or 100% */

  /* black */

  color: #101010;

  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.timelapse-bar > .line > .time-selector.selected > p {
  opacity: 1;
}

.tool-day-night {
  width: 24px;
  height: 24px;
}

.tool-day-night > svg {
  width: 100%;
  height: auto;
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.tool-day-night > svg > * {
  fill: #ffffff;
}

.tool-day-night > svg * {
  -webkit-filter: none;
          filter: none;
}

.dropdown-circuit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  text-align: center;

  color: #ffffff;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);

  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: opacity 0.5s ease;
}

.dropdown-circuit.drop {
  opacity: 0;
  pointer-events: none;
}

.dropdown-circuit > svg {
  width: 24px;
  height: auto;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.dropdown-circuit > svg > * {
  fill: #ffffff;
}

.dropdown-circuit > svg * {
  -webkit-filter: none;
          filter: none;
}

.circuits {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  height: 0.1vh;
  background: rgba(65, 65, 65, 0.7);
  opacity: 0;
  border-radius: 20px;
  padding: 0 0;
  transition: height 0.5s ease, opacity 0.5s ease;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.circuits.drop {
  z-index: 1;
  opacity: 0.75;
  pointer-events: all;
}

.circuits > p {
  margin: 0;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  width: 100%;

  /* white */

  color: #ffffff;
  padding: 10px 21px;
}

.circuits > p:hover {
  background: rgb(var(--main-color));
  cursor: pointer;
}

.dropdown-background {
  position: fixed;
  top: -4.63vh;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}

.circuits-menu {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.circuits-menu > .menu {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 45px);
  width: calc(1vw * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: rgb(var(--main-dark));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.circuits-menu > .menu > .choose-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.circuits-menu > .menu > .choose-label > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;

  /* white */

  color: #ffffff;
  margin: 0;
}

.circuits-menu > .menu > .choose-label > svg {
  width: 55px;
  height: 2px;
  margin-right: 16px;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.circuits-menu > .menu > .choose-label > svg > * {
  stroke: #ffffff;
  stroke-width: 2;
}

.circuits-menu > .menu > .m-circuits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 88px;
  margin: 32px 0 15.7vh 88px;
}

.circuits-menu > .menu > .m-circuits > p:first-child {
  margin: 0;
}

.circuits-menu > .menu > .m-circuits > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* white */

  color: #ffffff;
  margin: 36px 0 0 0;
}

.circuits-menu > .footer-bar {
  width: 100vw;
  height: 45px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.circuits-menu > .footer-bar > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.circuits-menu > .footer-bar > svg > * {
  fill: #000004;
}

.circuits-menu > .footer-bar > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* black */

  color: #101010;
  margin: 0;
}

.decor {
  position: fixed;
  top: 14.63vh;
  right: 2.4vw;
  z-index: 99;
}

.decor > svg {
  width: 60px;
  height: auto;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: all;
}

.decor > svg.active {
  opacity: 0;
  pointer-events: none;
}

.decor > svg * {
  -webkit-filter: none;
          filter: none;
}

.decor > .decor-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 21.82vw;
  height: 73.15vh;
  transition: opacity 0.3s ease;
}

.decor > .decor-container.active {
  opacity: 0.85;
  pointer-events: all;
}

.decor > .decor-container > .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.78vh;
  margin-bottom: 0.74vh;
}

.decor > .decor-container > .search-bar > svg {
  width: 24px;
}

.decor > .decor-container > .search-bar > svg > * {
  fill: #000004;
}

.decor > .decor-container > .search-bar > input {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  outline: none;
}

.decor > .decor-container > .styles-container {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.decor > .decor-container > .styles-container::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.decor > .decor-container > .styles-container > .style-container:first-child {
  margin-top: 0;
}

.decor > .decor-container > .styles-container > .style-container {
  margin-top: 16px;
  height: 23.89vh;
}

.decor > .decor-container > .styles-container > .style-container > img {
  width: 100%;
  height: 100%;
}

.decor > .decor-container > .styles-container > .style-container > .over {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.decor > .decor-container > .styles-container > .style-container > .over > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
  padding: 16px;
  color: #ffffff;
}

svg.active > * {
  fill: rgb(var(--main-color));
}

.modal-decor {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  background: rgba(35, 35, 35, 0.5);
}

.modal-decor > .decor-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  overflow: hidden;
}

.modal-decor > .decor-container > .search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modal-decor > .decor-container > .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.modal-decor > .decor-container > .search-bar > svg {
  width: 24px;
}

.modal-decor > .decor-container > .search-bar > svg > * {
  fill: #000004;
}

.modal-decor > .decor-container > .search-bar > input {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  outline: none;
  background: transparent;
  margin: 0 12px;
}

.modal-decor > .decor-container > .styles-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}

.modal-decor > .decor-container > .styles-container::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.modal-decor > .decor-container > .styles-container > .style-container:first-child {
  margin-top: 0;
}

.modal-decor > .decor-container > .styles-container > .style-container {
  width: 100%;
  height: 71.67vh;
  margin-top: 16px;
}

.modal-decor > .decor-container > .styles-container > .style-container > img {
  width: 100%;
  height: 100%;
}

.modal-decor > .decor-container > .styles-container > .style-container > .over {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.modal-decor > .decor-container > .styles-container > .style-container > .over > p {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, #000000 100%);
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
  padding: 16px;
  color: #ffffff;
}

.chatbox-container {
  width: 24px;
  height: 24px;
}

.chatbox-container > svg {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(0px 1.66667px 3.33333px #000000);
          filter: drop-shadow(0px 1.66667px 3.33333px #000000);
  cursor: pointer;
}

.chatbox-container > svg > * {
  fill: #ffffff;
}

.chatbox-container > .chatbox-window {
  position: fixed;
  bottom: 10vh;
  left: 3.23vw;
}

.header-container{height:48px;display:flex;width:100%}.header-container .title-container{background-color:rgb(var(--main-color));display:flex;align-items:center;width:85.5%;padding-left:4.8%}.header-container .close-box-container{display:flex;background-color:rgb(var(--main-dark));align-items:center;justify-content:center;width:14.5%;cursor:pointer}
.greeting-container{width:100%;height:43px;box-shadow:0px 4px 4px rgba(0,0,0,.25);display:flex;align-items:center}.greeting-container .greeting-text{padding-left:4.8%}
.messages-container{overflow:auto;margin-left:4.8%;margin-right:4.8%;margin-bottom:30px;margin-top:30px;height:373px;background-color:#fff;display:flex;flex-direction:column;justify-content:flex-end}.messages-container .chat-message{max-width:180px;margin-bottom:4px;padding:16px}.messages-container .client-message{background:rgb(var(--main-dark));color:#fff;border-radius:10px 10px 0px 10px;margin-left:calc(100% - 180px);white-space:pre-wrap;word-break:break-word;-webkit-user-select:text;-ms-user-select:text;user-select:text}.messages-container .support-message{background:#e9ebed;border-radius:10px 10px 10px 0px}
.title-indication{position:fixed;top:25vh;left:50%;-webkit-transform:translate(-50%, 0);transform:translate(-50%, 0);font-family:Mukta Vaani;font-style:normal;font-weight:bold;font-size:20px;line-height:51px;text-align:center;letter-spacing:.0025em;color:#fff;text-shadow:0px 2px 3px rgba(0,0,0,.8)}.input-text-container{height:48px;margin-bottom:16px;background-color:#fff}.input-text-container form{width:100%;height:48px;display:flex;flex-direction:row;align-items:center;justify-content:center;padding:0 16px}.input-text-container form .typo-body-chat{background:#eef1f6;border-radius:65px;resize:none;width:100%;height:100%;font-family:Nunito Sans;font-style:normal;font-weight:normal;font-size:16px;line-height:22px;display:flex;align-items:center;color:gray;padding:12px 16px;border:none}.input-text-container form .typo-body-chat.changed{color:#050505}.input-text-container form .typo-body-chat:focus{outline:none}.input-text-container form .typo-body-chat::-webkit-scrollbar{width:0;height:0}.input-text-container form svg{width:24px;height:24px;margin-left:16px;cursor:pointer}.input-text-container form svg *{fill:#c4c4c4}.input-text-container form svg.pa-active *{fill:rgb(var(--main-dark))}.input-text-container form button{background:transparent;border:none;padding:0;width:24px;height:24px;margin-left:16px;cursor:pointer}.input-text-container form button svg{width:100%;height:100%;margin-left:0}.input-text-container form button svg *{fill:rgb(var(--main-dark))}.input-text-container form button:focus{outline:none}
.chat-container{width:330px;background-color:#fff;box-shadow:0px 3px 6px rgba(0,0,0,.5);border-radius:5px;overflow:hidden}.typo-chat-title{font-family:Mukta Vaani;font-style:normal;font-weight:bold;font-size:20px;line-height:33px;color:#fff}.typo-body-chat{font-family:Nunito Sans;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;color:#000}.typo-chat-input{color:gray}
.group-container > svg > * {
  fill: #ffffff;
}

.group-container > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.group-container {
  width: 24px;
  height: 24px;
}

.invitation-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.9);
  z-index: 1;
}

.mobile-navBar > .group-container {
  margin-right: 16px;
}

.mobile-navBar > .group-container > svg {
  width: 24px;
  height: 24px;
  -webkit-filter: none;
          filter: none;
}

.mobile-navBar > .group-container > svg > * {
  fill: #949494;
}

.invitation-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 673px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media only screen and (max-width: 720px) {
  .invitation-container {
    height: 250px !important;
    overflow: auto;
  }
}

.invitation-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.invitation-container > p {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin-bottom: 0;
}

.invitation-container > .url {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 16px;
}

.invitation-container > .url > input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  border: none;
  width: 100%;
}

.invitation-container > .url > input:focus {
  outline: none;
}

.invitation-container > .url > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.invitation-container > .url > svg > * {
  fill: #000000;
}

.invitation-container > form {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invitation-container > form > label {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
}

.invitation-container > form > input.name {
  height: 48px;
  width: 100%;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #808080;
  padding: 12px;
  margin-top: 16px;
}

.invitation-container > form > input:focus {
  outline: none;
}

.invitation-container > form > input.enter-btn {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-container > .video {
  background: #000004;
  border-radius: 5px;
  height: 236px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-container > .video > video {
  width: 100%;
  height: auto;
  border-radius: 0px;
}

.invitation-container > .video-buttons {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.invitation-container > .video-buttons > .btn {
  width: 48px;
  height: 48px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.invitation-container > .video-buttons > .btn:last-child {
  margin-right: 0px;
}

.invitation-container > .video-buttons > .btn > svg {
  width: 24px;
  height: 24px;
}

.invitation-container > .video-buttons > .btn > svg > * {
  fill: #000004;
}

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

@media only screen and (max-width: 720px) {
  .login-container {
    height: 250px !important;
  }

  .login-container > form > input {
    margin-top: 8px !important;
  }
}

.login-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.login-container > form {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container > form > label {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
}

.login-container > form > input {
  height: 48px;
  width: 100%;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #808080;
  padding: 12px;
  margin-top: 16px;
}

.login-container > form > input:focus {
  outline: none;
}

.login-container > form > input.submit {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.point-ask-container {
  position: absolute;
  width: 30vw;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
  z-index: 500;
  cursor: default !important;
  padding-bottom: 1vw;
  overflow: visible;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.point-ask-container.active {
  opacity: 1;
  pointer-events: all;
}

.point-ask-container * {
  cursor: default !important;
}

.point-ask-container > .point-ask-header {
  background: rgb(var(--main-color));
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.point-ask-container > .point-ask-header > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.2vw;
  line-height: 117%;

  color: #ffffff;
  padding-left: 1vw;
}

.point-ask-container > .point-ask-header > .close {
  background: rgb(var(--main-dark));
  border-radius: 0px;
  height: 3.2vw;
  width: 3.2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.point-ask-container > .point-ask-header > .close > svg {
  width: 2vw;
  height: auto;
  cursor: pointer !important;
}

.point-ask-container > .point-ask-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  width: 100%;
}

.point-ask-container > .point-ask-body > .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.point-ask-container > .point-ask-body > .info > input {
  width: 100%;
  height: 2.5vw;
  padding: 0 0.5vw;
  cursor: text !important;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 162%;
  /* identical to box height, or 162% */

  /* black */

  color: #101010;

  border: 1px solid #949494;
  box-sizing: border-box;
}

.point-ask-container > .point-ask-body > textarea {
  width: 100%;
  margin-top: 1vw;
  height: 10vw;
  cursor: text !important;
  padding: 0.5vw;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 162%;
  /* identical to box height, or 162% */

  /* black */

  color: #101010;

  border: 1px solid #949494;
  box-sizing: border-box;

  resize: none;
}

.point-ask-container > .point-ask-footer {
  padding: 0 1vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.point-ask-container > .point-ask-footer > input {
  width: 10vw;
  height: 3vw;
  background: rgb(var(--main-color));
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  cursor: pointer !important;
  outline: none;
}

.point-ask-container > .arrow-down {
  width: 0;
  height: 0;
  border-left: 1vw solid transparent;
  border-right: 1vw solid transparent;
  border-top: 1.5vw solid #ffffff;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 90%);
          transform: translate(-50%, 90%);
}

.point-ask-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
}

.point-ask-back > .click {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.point-ask-success {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25vw;

  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  padding: 1vw;

  pointer-events: none;
}

.point-ask-success > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.08vw;
  line-height: 110%;

  /* black */

  color: #101010;
  width: 100%;
  padding-right: 1vw;
}

.point-ask-success > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 144%;
  /* or 144% */

  color: #141414;
  width: 100%;
  padding-right: 1vw;
}

.point-ask-success > .close-btn {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: right;

  /* gold-linea */

  color: rgb(var(--main-color));

  width: 100%;
  margin: 1vw 0 0.2vw 0;
}

.mobile-pa-form {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: 500;
}

.mobile-pa-form.active {
  opacity: 1;
  pointer-events: all;
}

.mobile-pa-form > .mobile-pa-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgb(var(--main-color));
  border-radius: 10px 10px 0px 0px;
}

.mobile-pa-form > .mobile-pa-header > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.8vw;
  line-height: 110%;

  color: #ffffff;

  padding-left: 4vw;
}

.mobile-pa-form > .mobile-pa-header > .close {
  width: 8vw;
  height: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: rgb(var(--main-dark));
  border-radius: 0px 10px 0px 0px;
}

.mobile-pa-form > .mobile-pa-header > .close > svg {
  width: 5vw;
  height: auto;
}

.mobile-pa-form > .mobile-pa-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5vw 4vw;
}

.mobile-pa-form > .mobile-pa-body > .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vw;
}

.mobile-pa-form > .mobile-pa-body > .info > input {
  width: 100%;
  height: 6vw;
  padding: 1vw;
}

.mobile-pa-form > .mobile-pa-body > textarea {
  height: 14vw;
  width: 100%;
  padding: 1vw;
}

.mobile-pa-form > .mobile-pa-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4vw 2vw 0;
}

.mobile-pa-form > .mobile-pa-footer > input {
  width: 14vw;
  height: 6vw;
  background: rgb(var(--main-color));
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  outline: none;
}

.mobile-pa-success {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 500;

  padding: 4vw;
}

.mobile-pa-success > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.8vw;
  line-height: 110%;

  color: #110011;

  width: 100%;
}

.mobile-pa-success > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.1vw;
  line-height: 110%;

  color: #110011;

  width: 100%;
}

.pulse-container {
  position: absolute;
  width: 0;
  height: 0;
  background: black;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
}

.pulse-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: rgb(var(--main-color));
  width: 100px;
  height: 100px;
  opacity: 0;
  z-index: 100;
  -webkit-filter: drop-shadow(0px 1.6px 2.4px #000000);
          filter: drop-shadow(0px 1.6px 2.4px #000000);
  -webkit-animation: scaleIn 1.5s infinite cubic-bezier(0.2, 0.2, 0.2, 0.2);
          animation: scaleIn 1.5s infinite cubic-bezier(0.2, 0.2, 0.2, 0.2);
}

.pulse-animation::after {
  -webkit-transform: translate(-50px, -50px);
          transform: translate(-50px, -50px);
}

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: translate(-50px, -50px) scale(0.25, 0.25);
            transform: translate(-50px, -50px) scale(0.25, 0.25);
    opacity: 0.5;
  }
  to {
    -webkit-transform: translate(-50px, -50px) scale(1, 1);
            transform: translate(-50px, -50px) scale(1, 1);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    -webkit-transform: translate(-50px, -50px) scale(0.25, 0.25);
            transform: translate(-50px, -50px) scale(0.25, 0.25);
    opacity: 0.5;
  }
  to {
    -webkit-transform: translate(-50px, -50px) scale(1, 1);
            transform: translate(-50px, -50px) scale(1, 1);
    opacity: 0;
  }
}

.map-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 22vw;
  height: auto;
  margin: 5vh 6vw 0 0;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.map-container.active {
  opacity: 0.35;
  pointer-events: all;
}

.map-container.active:hover {
  opacity: 1;
}

.map-container h3 {
  flex: 1 1;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.7vw;
  line-height: 2.3vw;
  margin: 2.5vh 0 0 15px;
  padding: 0 6px;
  color: #3d3d3d;
  align-self: flex-start;
}

.map-container .info-container {
  flex: 5 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 2.5vh 0 2.5vh 11px;
}

.map-container .map,
.mobile-map > .map {
  flex: 5 1;
  position: relative;
}

.map-container .map div,
.mobile-map > .map div {
  width: 100%;
  height: 100%;
}

.map-container .map svg,
.mobile-map > .map svg {
  height: 100%;
  width: 100%;
}

.map-container .map svg path,
.mobile-map > .map svg path {
  fill: rgba(var(--main-color), 0);
  transition: fill 0.3s ease;
}

.map-container .map svg > .hoverable:hover {
  fill: rgba(var(--main-color), 0.5);
}

#tooltip {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 10%;
  left: 30%;
  color: red;
  display: none;
  z-index: 10;
  pointer-events: none;
}

.hovering {
  display: block !important;
}

.map-container .map svg .selected,
.mobile-map > .map svg .selected {
  fill: rgba(var(--main-color), 0.8);
}

.map-container .sector-container,
.mobile-map > .sector-container {
  position: relative;
  border-left: 1px solid #bfbfbf;
}

.map-container .sector-container p,
.mobile-map > .sector-container > p {
  font-family: 'Merriweather Sans' T;
  font-size: 2.5vh;
  line-height: 4vh;

  white-space: nowrap;
  padding: 0 6px;
  margin: 0;

  text-align: center;

  color: #000000;
  width: auto;
  height: 4vh;
}

.map-container .sector-container p.active,
.mobile-map > .sector-container > p.active {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.5vh;
  line-height: 4vh;

  text-align: center;

  color: #000000;
  width: auto;
  height: 4vh;

  background: rgba(var(--main-color), 0.5);
}

.map-container .sector-container p:hover {
  background: rgba(var(--main-color), 0.2);
}

.map-container .map svg .hoverable title {
  position: absolute;
  display: block;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 35px;
  padding: 0 12px 2px 12px;
  text-align: center;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  color: #000000;
  bottom: 100%;

  transition: opacity 0.3s ease;
}

.mobile-map-container {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  width: 100vw;
  height: 100vh;
}

.mobile-map-container.active {
  opacity: 1;
  pointer-events: all;
}

.mobile-map-container > .mobile-map {
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: 75%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 2vw 0;
}

.mobile-map-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.closed-map-icon {
  position: fixed;
  right: 2.4vw;
  top: 4.63vh;
  z-index: 90;
  cursor: pointer;
}

.closed-map-icon > svg {
  width: 60px;
  height: auto;
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
}

.closed-map-icon > svg * {
  -webkit-filter: none;
          filter: none;
}

.map-wrapper {
  position: fixed;
  right: 2.4vw;
  top: 4.63vh;
  height: auto;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.48vh 0.83vw;
  opacity: 0.35;
  transition: opacity 0.5s ease;
}

.map-wrapper:hover {
  opacity: 1;
}

.map-wrapper > .btn-container {
  width: 100%;
  height: 2.96vh;
  margin-bottom: 1.48vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.map-wrapper > .btn-container > .direction-btn {
  position: absolute;
  left: 0;
  width: auto;
  height: 100%;
  padding: 0.74vh 0.83vw 0.74vh 0.73vw;
  background: rgb(var(--main-color));
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.map-wrapper > .btn-container > .direction-btn > svg {
  width: 24px;
  cursor: pointer;
}

.map-wrapper > .btn-container > .direction-btn > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
}

.map-wrapper > .btn-container > svg {
  width: 24px;
  height: auto;
  margin-left: 16px;
  cursor: pointer;
}

.map-wrapper > .btn-container > svg > * {
  fill: #101010;
}

.maps-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.5);
}

.mobile-maps-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: auto;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0.3vw 1vw 1vw 1vw;
  z-index: 398;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mobile-maps-wrapper > .mobile-btn-container {
  width: 100%;
  height: 4vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.mobile-maps-wrapper > .mobile-btn-container > svg {
  width: 3.5vw;
  margin-left: 2vw;
}

.mobile-maps-wrapper > .mobile-btn-container > svg > * {
  fill: #101010;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn {
  position: absolute;
  left: 0;
  width: 14vw;
  height: auto;
  padding: 0 1vw 0 0.6vw;
  background: rgb(var(--main-color));
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn > svg {
  width: 3vw;
  height: auto;
}

.mobile-maps-wrapper > .mobile-btn-container > .direction-btn > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.7vw;
  line-height: 114%;
  padding: 1px 0 0 0;
  margin: 0;
  /* identical to box height, or 114% */

  /* White */

  color: #ffffff;
}

.lobby-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(35, 35, 35, 0.5);
  z-index: 100;
}

.lobby-container > .lobby {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 16px;
  border-radius: 5px;
  height: 30vh;
  width: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.lobby-container > .lobby > label {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  white-space: nowrap;
  letter-spacing: 0.005em;
  color: #000004;
}

.lobby-container > .lobby > .name {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: solid 2px rgba(35, 35, 35, 0.7);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;
  letter-spacing: 0.005em;
  color: #000004;
}

.lobby-container > .lobby > .enter-btn {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgb(var(--main-color));
  width: 84px;
  height: 36px;
  border: 2px solid rgb(var(--main-color));
  box-sizing: border-box;
  border-radius: 5px;
  background: none;
  cursor: pointer;
}

.lobby-container > .lobby > input:focus {
  outline: none;
}

.lobby-container > .lobby > input:required {
  border: solid 2px #d92626;
}

.lby-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 561px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 100;
}

@media only screen and (max-width: 720px) {
  .lby-container {
    height: 250px !important;
    overflow: auto;
  }
}

.lby-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.lby-container > p {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin-bottom: 0;
}

.lby-container > .url {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 16px;
}

.lby-container > .url > input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  border: none;
  width: 100%;
}

.lby-container > .url > input:focus {
  outline: none;
}

.lby-container > .url > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.lby-container > .url > svg > * {
  fill: #000000;
}

.lby-container > form {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lby-container > form > label {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
}

.lby-container > form > input.name {
  height: 48px;
  width: 100%;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #808080;
  padding: 12px;
  margin-top: 16px;
}

.lby-container > form > input:focus {
  outline: none;
}

.lby-container > form > input.enter-btn {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lby-container > .video {
  background: #000004;
  border-radius: 5px;
  height: 236px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lby-container > .video > video {
  width: 100%;
  height: auto;
  border-radius: 0px;
}

.lby-container > .video-buttons {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lby-container > .video-buttons > .btn {
  width: 48px;
  height: 48px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
}

.lby-container > .video-buttons > .btn:last-child {
  margin-right: 0px;
}

.lby-container > .video-buttons > .btn > svg {
  width: 24px;
  height: 24px;
}

.lby-container > .video-buttons > .btn > svg > * {
  fill: #000004;
}

.remote-bar {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  padding: 4px;
}

.remote-bar > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.remote-bar > svg > * {
  fill: #ffffff;
}

.media-info {
  height: 24px;
}

.media-info > svg {
  width: 24px;
  height: 100%;
}

.media-info > svg > * {
  fill: #ffffff;
}

.remote-tools {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: #212221;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
}

.remote-tools.active {
  opacity: 1;
  pointer-events: all;
}

.remote-tool {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
}

.remote-tool > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.remote-tool > svg > * {
  fill: #ffffff;
}

.prompt-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
}

.changed-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.changed-container > svg {
  width: 70px;
  height: 70px;
}

.changed-container > svg > * {
  fill: #000000;
}

.changed-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.changed-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.changed-container > p > svg > * {
  fill: #000000;
}

.changed-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.changed-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.changed-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}

.video-buttons-container {
  position: fixed;
  left: 50%;
  bottom: 10vh;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: #000004;
  opacity: 0.85;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 8px;
}

@media only screen and (max-width: 720px) {
  .video-buttons-container {
    width: 220px;
    bottom: 15vh;
  }
}

.video-buttons-container > .btn-wrap {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 8px;
}

.video-buttons-container > .btn-wrap:first-child {
  margin-left: 0px;
}

.video-buttons-container > .btn-wrap > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.video-buttons-container > .btn-wrap > svg > * {
  fill: #ffffff;
}

.btn-wrap.red {
  background: #de4747;
}

.btn-wrap.green {
  background: #335610;
}

.btn-wrap.gray {
  background: #939393;
}

.btn-wrap > svg.green > * {
  fill: #92de47;
}

.live-feed {
  position: fixed;
  bottom: 5vh;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.55);
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.0015em;
  color: #000004;
  padding: 8px 16px;
}

.live-feed.guide {
  background: #92de47;
}

.live-feed.guided {
  background: #e67373;
}

.collapse {
  position: absolute;
  top: 50%;
  left: 105%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, 0.3);
  border-radius: 3.18182px;
  cursor: pointer;
}

.collapse > svg {
  width: 24px;
  height: 24px;
}

.collapse > svg > * {
  fill: #ffffff;
}

.collapsed {
  position: fixed;
  left: 50%;
  bottom: 10vh;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

@media only screen and (max-width: 720px) {
  .collapsed {
    bottom: 15vh;
  }
}

.collapsed > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.collapsed > svg > * {
  fill: #000004;
}

.finish-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.6);
}

.finish-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.finish-container > h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  /* identical to box height, or 80% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.finish-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.finish-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.finish-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: #000004;
  width: 116px;
  height: 36px;
}

.finish-container > .buttons > .terminar {
  cursor: pointer;
  background: #de4747;
  border-radius: 5px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* white */

  color: #ffffff;
  width: 116px;
  height: 36px;
}

.live-mode-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 4.96681px;
  z-index: 1;
  padding: 16px;
}

.live-mode-container > svg {
  width: 70px;
  height: 70px;
}

.live-mode-container > svg > * {
  fill: #000000;
}

.live-mode-container > p {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: start;
  letter-spacing: 0.005em;

  color: #000000;
  width: 100%;
  margin: 0;
}

.live-mode-container > p > svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  vertical-align: text-bottom;
}

.live-mode-container > p > svg > * {
  fill: #000000;
}

.live-mode-container > p > strong {
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 20px;
}

.live-mode-container > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.live-mode-container > .buttons > .cancel {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: #000004;
  width: 116px;
  height: 36px;
}

.live-mode-container > .buttons > .continue {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;

  color: rgb(var(--main-color));
  width: 116px;
  height: 36px;
}

.invite-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 438px;
  height: 229px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.invite-container > h1 {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin: 0;
  padding: 0;
}

.invite-container > p {
  width: 100%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #000000;
  margin-bottom: 0;
}

.invite-container > .url {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 16px;
}

.invite-container > .url > input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  border: none;
  width: 100%;
}

.invite-container > .url > input:focus {
  outline: none;
}

.invite-container > .url > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.invite-container > .url > svg > * {
  fill: #000000;
}

.invite-container > .copy-btn {
  width: 100%;
  height: 48px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(var(--main-color));
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form > div {
  width: 100%;
  margin-bottom: 1em;
}
form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
button:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
}

.room {
  position: fixed;
  bottom: 10vh;
  left: 3.13vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 292px;
  z-index: 101;
}

@media only screen and (max-width: 720px) {
  .room {
    width: 62px;
    bottom: 15vh;
  }

  .local-participant {
    height: 62px !important;
  }

  .remote-participants > .participant {
    width: 100% !important;
    height: 62px !important;
  }

  .participant > .video-chat-container > video {
    height: 100% !important;
    width: 62px !important;
  }

  .participant > .remote-bg > .remote-tools {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .participant > .remote-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, 0.3);
  }

  .video-overlay {
    width: 62px !important;
    height: 62px !important;
  }
}

.room::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}
.room button {
  position: absolute;
  top: 0;
  right: 20px;
}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  width: 100%;
  height: 170px;
  text-align: center;
}
.remote-participants {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  pointer-events: all;
  /*max-height: 380px;
  overflow-y: auto;*/
}
.remote-participants::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}
.remote-participants > .participant {
  height: 142px;
  margin-bottom: 8px;
}
.participant {
  background: none;
  border-radius: 5px;
  display: inline-block;
  pointer-events: all;
  height: 100%;
}
.participant:first-child {
  padding-left: 0;
}
.participant:last-child {
  margin-right: 0;
  padding-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

.video-chat-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
}

.video-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000004;
  color: #ffffff;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 4px;
}

.local-participant > .participant > .video-chat-container > video {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
  width: 100%;
  height: auto;
}

.video-chat-container > video {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
  height: 100%;
}

.remote-video-buttons-container {
  position: absolute;
  bottom: 0;
}

footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

.timer {
  position: fixed;
  bottom: 2vh;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #ffffff;
}

.start-container {
  width: 100%;
  position: absolute;
  top: 65%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.start-container > .start {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(95, 35, 72);
  background: rgba(255, 255, 255, 0.3);
  width: 75px;
  height: 50px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid rgb(95, 35, 72);
  letter-spacing: 2px;
  transition: all ease 0.3s;
}

.start-container > .start:hover {
  background: rgb(95, 35, 72);
  color: #ffffff;
}

.start-container > .start.en {
  margin-right: 20px;
}

.start-container > .start.de {
  margin-left: 20px;
}

