.hotspot-img > .content {
  position: absolute;
  width: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0;
  margin: 0;

  -webkit-transform-origin: 50% calc(100% + 6em);
  transform-origin: 50% calc(100% + 6em);

  -webkit-transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);
  transform: translate3d(-50%, -100%, 0) translate3d(0, -40px, 0);

  -webkit-transition: opacity 0.3s, -webkit-transform 0.5s;
  transition: opacity 0.3s, transform 0.5s;

  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.hotspot-img > .content.active {
  opacity: 1;
}

.hotspot-img > .content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  margin: 0;
  padding: 16px 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hotspot-img > svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.7));
  transition: width 0.3s ease;
}

.hotspot-img > svg:hover {
  width: 60px;
}

.hotspot-img > svg * {
  filter: none;
}

.hotspot-img > .content > .image-container {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
}

.hotspot-img > .content > .image-container > img {
  width: auto;
  height: 30.74vh;
}

.hotspot-img .content p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #141414;
  margin: 0 11px 15px 11px;
}

.hotspot-img .hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 4vh;
  opacity: 0.9;
}

.hotspot-img .hotspot-hover {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 8vh;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.hotspot-img .hotspot-hover.active {
  opacity: 1;
}

.hotspot-img:hover .hotspot-hover {
  opacity: 1;
}

.hotspot-img .hotspot-oval {
  position: absolute;
  transform: translate(-50%, -50%);
  width: auto;
  height: 10.3vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.hotspot-img .hotspot-oval.active {
  opacity: 1;
}

.img-hotspot-container {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.img-hotspot-container.active {
  opacity: 1;
  pointer-events: all;
}

.img-hotspot-container > .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 35, 0.5);
}

.img-hotspot-container > .mobile-content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.img-hotspot-container > .mobile-content > h3 {
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  margin: 0;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img-hotspot-container > .mobile-content > h3 > .close {
  background: rgb(var(--main-dark));
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-hotspot-container > .mobile-content > h3 > .close > svg {
  width: 24px;
  height: 24px;
}

.img-hotspot-container > .mobile-content > h3 > .close > svg > * {
  fill: #ffffff;
}

.img-hotspot-container > .mobile-content > .image-container {
  width: 100%;
  height: calc(100% - 48px);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-hotspot-container > .mobile-content > .image-container > img {
  height: auto;
  width: auto;
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  pointer-events: all;
}

.fullscreen-container > img {
  height: 100%;
  width: auto;
}

.fullscreen-container > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.fullscreen-container > svg > * {
  fill: #ffffff;
}
