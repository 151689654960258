.point-ask-container {
  position: absolute;
  width: 30vw;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
  z-index: 500;
  cursor: default !important;
  padding-bottom: 1vw;
  overflow: visible;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.point-ask-container.active {
  opacity: 1;
  pointer-events: all;
}

.point-ask-container * {
  cursor: default !important;
}

.point-ask-container > .point-ask-header {
  background: rgb(var(--main-color));
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.point-ask-container > .point-ask-header > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.2vw;
  line-height: 117%;

  color: #ffffff;
  padding-left: 1vw;
}

.point-ask-container > .point-ask-header > .close {
  background: rgb(var(--main-dark));
  border-radius: 0px;
  height: 3.2vw;
  width: 3.2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.point-ask-container > .point-ask-header > .close > svg {
  width: 2vw;
  height: auto;
  cursor: pointer !important;
}

.point-ask-container > .point-ask-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  width: 100%;
}

.point-ask-container > .point-ask-body > .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.point-ask-container > .point-ask-body > .info > input {
  width: 100%;
  height: 2.5vw;
  padding: 0 0.5vw;
  cursor: text !important;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 162%;
  /* identical to box height, or 162% */

  /* black */

  color: #101010;

  border: 1px solid #949494;
  box-sizing: border-box;
}

.point-ask-container > .point-ask-body > textarea {
  width: 100%;
  margin-top: 1vw;
  height: 10vw;
  cursor: text !important;
  padding: 0.5vw;

  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 162%;
  /* identical to box height, or 162% */

  /* black */

  color: #101010;

  border: 1px solid #949494;
  box-sizing: border-box;

  resize: none;
}

.point-ask-container > .point-ask-footer {
  padding: 0 1vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.point-ask-container > .point-ask-footer > input {
  width: 10vw;
  height: 3vw;
  background: rgb(var(--main-color));
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  cursor: pointer !important;
  outline: none;
}

.point-ask-container > .arrow-down {
  width: 0;
  height: 0;
  border-left: 1vw solid transparent;
  border-right: 1vw solid transparent;
  border-top: 1.5vw solid #ffffff;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 90%);
}

.point-ask-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
}

.point-ask-back > .click {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.point-ask-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25vw;

  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  padding: 1vw;

  pointer-events: none;
}

.point-ask-success > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 1.08vw;
  line-height: 110%;

  /* black */

  color: #101010;
  width: 100%;
  padding-right: 1vw;
}

.point-ask-success > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 144%;
  /* or 144% */

  color: #141414;
  width: 100%;
  padding-right: 1vw;
}

.point-ask-success > .close-btn {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 0.9vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: right;

  /* gold-linea */

  color: rgb(var(--main-color));

  width: 100%;
  margin: 1vw 0 0.2vw 0;
}

.mobile-pa-form {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: 500;
}

.mobile-pa-form.active {
  opacity: 1;
  pointer-events: all;
}

.mobile-pa-form > .mobile-pa-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgb(var(--main-color));
  border-radius: 10px 10px 0px 0px;
}

.mobile-pa-form > .mobile-pa-header > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.8vw;
  line-height: 110%;

  color: #ffffff;

  padding-left: 4vw;
}

.mobile-pa-form > .mobile-pa-header > .close {
  width: 8vw;
  height: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: rgb(var(--main-dark));
  border-radius: 0px 10px 0px 0px;
}

.mobile-pa-form > .mobile-pa-header > .close > svg {
  width: 5vw;
  height: auto;
}

.mobile-pa-form > .mobile-pa-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5vw 4vw;
}

.mobile-pa-form > .mobile-pa-body > .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vw;
}

.mobile-pa-form > .mobile-pa-body > .info > input {
  width: 100%;
  height: 6vw;
  padding: 1vw;
}

.mobile-pa-form > .mobile-pa-body > textarea {
  height: 14vw;
  width: 100%;
  padding: 1vw;
}

.mobile-pa-form > .mobile-pa-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4vw 2vw 0;
}

.mobile-pa-form > .mobile-pa-footer > input {
  width: 14vw;
  height: 6vw;
  background: rgb(var(--main-color));
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2vw;
  line-height: 89%;
  /* identical to box height, or 89% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  outline: none;
}

.mobile-pa-success {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -60%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 500;

  padding: 4vw;
}

.mobile-pa-success > h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.8vw;
  line-height: 110%;

  color: #110011;

  width: 100%;
}

.mobile-pa-success > p {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 2.1vw;
  line-height: 110%;

  color: #110011;

  width: 100%;
}
