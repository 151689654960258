.modal-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  overflow: hidden;
}

.modal-text > .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-text > .head > h1 {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  background: rgb(var(--main-color));
}

.modal-text > .head > .svg-container {
  width: 48px;
  height: 48px;
  background: rgb(var(--main-dark));
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-text > .head > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.modal-text > .head > .svg-container > svg > * {
  fill: #ffffff;
}

.modal-text > .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  padding: 16px;
}

.modal-text > .content > img {
  max-width: 40vw;
  max-height: 60vh;
}

.modal-text > .content > p {
  width: 336px;
  height: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  padding-left: 16px;
  margin: 0;
}

.mobile-modal-text {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 75%;
  transform: translate(-50%, -60%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-modal-text > .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-modal-text > .header > h3 {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 16px 0 16px;
  color: #ffffff;
  background: rgb(var(--main-color));
  width: 100%;
  height: 48px;
}

.mobile-modal-text > .header > .svg-container {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--main-dark));
}

.mobile-modal-text > .header > .svg-container > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-text > .header > .svg-container > svg > * {
  fill: #ffffff;
}

.mobile-modal-text > .footer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background: #ffffff;
  border-top: 1px solid #808080;
}

.mobile-modal-text > .footer > svg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.mobile-modal-text > .footer > svg > * {
  fill: #808080;
}

.mobile-modal-text > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-text > .image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: hidden;
}

.mobile-modal-text > .image > .container {
  width: 100%;
  height: 100%;
}

.mobile-modal-text > .image > .container > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.mobile-modal-text > .image > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-text > .image > svg > * {
  fill: #808080;
}

.mobile-modal-text > .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 103px);
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0 16px;
  overflow: scroll;
}

.mobile-modal-text > .info::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-text > .info > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.005em;

  /* davys-grey-100 */

  color: #000004;
  margin: 0;
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
  background: #000004;
  z-index: 99;
  pointer-events: all;
}

.full > svg {
  width: 24px;
  height: 24px;
}

.full > svg > * {
  fill: #808080;
}

.full > img {
  height: 100%;
  max-width: 90%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.full > .full-close {
  position: absolute;
  top: 16px;
  right: 16px;
}
