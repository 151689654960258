.navBar-modal,
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  z-index: 199;
  transition: width 0.5s ease, height 0.5s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navBar-modal > img {
  max-height: 58.56vh;
}

.modal > img {
  max-height: 58.56vh;
}

.navBar-modal > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.modal > .selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-x: scroll;
}

.navBar-modal > .selector > .selector-img-container,
.modal > .selector > .selector-img-container {
  position: relative;
  opacity: 0.5;
  height: 7.78vh;
  width: 6.56vw;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin-right: 16px;
}

.navBar-modal > .selector > .selector-img-container > .selector-img,
.modal > .selector > .selector-img-container > .selector-img {
  width: 100%;
  height: 100%;
}

.selector > .selector-img-container:hover {
  opacity: 1;
}

.selector > .selector-img-container.active {
  opacity: 1;
}

.selector > .selector-img-container > .border {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.selector > .selector-img-container.active > .border {
  opacity: 1;
  border: 3px solid rgb(var(--main-color));
}

.navBar-modal > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 16px 0 24px 0;
}

.modal > p {
  width: 100%;
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.04vw;
  line-height: 100%;
  color: #000004;
  margin: 1.48vh 0 2.22vh 0;
}

.mobile-modal-galeria {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -60%);
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-modal-galeria > .main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 36px);
}

.mobile-modal-galeria > .main > img {
  height: 100%;
  width: auto;
}

.mobile-modal-galeria > .main > svg {
  width: 24px;
  height: 24px;
}

.mobile-modal-galeria > .main > svg > * {
  fill: #949494;
}

.mobile-modal-galeria > .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
}

.mobile-modal-galeria > .footer > svg {
  width: 24px;
  margin-right: 16px;
}

.mobile-modal-galeria > .footer > svg > * {
  fill: #949494;
}

.mobile-modal-galeria > .footer > svg.active > * {
  fill: rgb(var(--main-color));
}

.mobile-modal-galeria > .footer > p {
  font-family: 'Mukta Vaani', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  color: #000004;
  margin: 0;
}

.mobile-modal-galeria > .mobile-selector {
  height: calc(100% - 36px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
}

.mobile-modal-galeria > .mobile-selector::-webkit-scrollbar {
  width: 0; /* width of the entire scrollbar */
  height: 0;
}

.mobile-modal-galeria > .mobile-selector > .selector-img-container {
  height: 100px;
  width: 30%;
}

.mobile-modal-galeria > .mobile-selector > .selector-img-container > .selector-img {
  width: 100%;
  height: 100%;
}
